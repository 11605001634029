import React from 'react';
import {ModuleOfPlatform} from './ModuleOfPlatform';
import styles from './index.module.scss';

interface IModuleOfPlatform {
  id: string
  imgUrl: string
  title: string
  urlLink: string
}

const modulesOfPlatform: Array<IModuleOfPlatform> = [
  {
    id: '91965be2-5080-473b-924b-7ec93082174a',
    imgUrl: 'https://sbidea.ru/attachments/1/resized_for_show_14/e578c6-314b-463c-a19f-a3b721159976/module_surveys_img.png',
    title: 'Опросы',
    urlLink: '/legacy/sign-in?target_uri=https://sbidea.ru/CJM'
  },
  {
    id: '8d24e672-3075-44c9-bafb-24cb16b2ceac',
    imgUrl: 'https://sbidea.ru/attachments/1/resized_for_show_a6/d114f2-2872-4661-a277-7590ffbe79a1/module_communities_img.png',
    title: 'Сообщества',
    urlLink: '/legacy/sign-in?target_uri=https://sbidea.ru/community'
  },
  {
    id: 'b37b49dc-c52a-4899-ad8d-8e680ecd49de',
    imgUrl: 'https://sbidea.ru/attachments/1/resized_for_show_dc/2e9b9f-de91-4abc-9af6-22dde5844485/module_projects_img.png',
    title: 'Проекты',
    urlLink: '/legacy/sign-in?target_uri=https://sbidea.ru/main-project-tb'
  },
  {
    id: '58b65cb5-c64e-45dc-8103-8eec154bdf0d',
    imgUrl: 'https://sbidea.ru/attachments/1/resized_for_show_03/004fe4-6616-402d-9e95-4b11fce8b8ce/module_landings_img.png',
    title: 'Корпоративный движ',
    urlLink: '/legacy/sign-in?target_uri=https://sbidea.ru/corpevents'
  }
]

const ModulesOfPlatform = () => {
  return (
    <div className={ styles.main }>
      {
        modulesOfPlatform.map(
          module => (
            <React.Fragment key={ module.id || '' }>
              <ModuleOfPlatform key={ module.id } imgUrl={ module.imgUrl } title={ module.title } urlLink={ module.urlLink }/>
              <div className={ styles.separator }/>
            </React.Fragment>
          )
        )
      }
    </div>
  );
};

export { ModulesOfPlatform };