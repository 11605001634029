import {Link} from 'react-router-dom';
import styles from './index.module.scss';

const HomePageForAnonymous = () => {
  return (
    <div className={ styles.main }>
      <div className={ styles.content }>
        <div className={ styles.text_level1 }>
          Один шаг и ты получишь доступ к сервису СберИдея
        </div>

        <div className={ styles.text_level2 }>
          нужно лишь авторизоваться
        </div>

        <Link to='/auth'>
          Войти
        </Link>

        <div className={ styles.image }>
          <img src='https://sbidea.ru/attachments/1/resized_for_show_74/0a7693-b5ff-4163-99ed-7e04b097b37d/img_for_anonymous.png' alt=""/>
        </div>
      </div>
    </div>
  );
};

export { HomePageForAnonymous };