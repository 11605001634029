import { lazy } from 'react'
import { Route } from 'react-router-dom';
import {AvailableRoute} from '../../AvailableRoute';

const SurveysDashboard = lazy(() => import('../').then(({ SurveysDashboard }) => ({ default: SurveysDashboard })));
const SingleSurveyDashboard = lazy(() => import('../SingleSurveyDashboard').then(({ SingleSurveyDashboard }) => ({ default: SingleSurveyDashboard })));

const DashboardRoutes = (
	<Route path="dashboard" element={<AvailableRoute moduleName='dashboard'/>}>
		<>
			<Route index element={<SurveysDashboard/>}/>
			<Route path=':id'>
				<Route index element={<SingleSurveyDashboard/>}/>
			</Route>
			{/*<Route path="*" element={<Navigate to="/dashboard" replace />} />*/}
		</>
	</Route>
);

export { DashboardRoutes };