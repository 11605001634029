import React, {useState} from 'react';
import { PopUpBackground } from '..';
import styles from './style.module.scss';
import cn from 'classnames';
import {ReactComponent as IconClose} from '../../../img/icons/close.svg';

interface Props {
  children?: React.ReactNode;
  visible?: boolean;
  withBlur?: boolean;
  onOuterClick?: (params?: any) => void;
  popUpBackgroundClassName?: string;
  popUpClassName?: string;
  unmountOnClose?: boolean;
  title?: string
}

export const PopUp_v2 = ({
  unmountOnClose,
  children,
  withBlur,
  visible,
  onOuterClick = () => {},
  popUpBackgroundClassName,
  popUpClassName,
  title
}: Props) => {

  const [startPos, setStartPos] = useState({x: 0, y: 0});
  const [touchPos, setTouchPos] = useState({x: 0, y: 0});

  const TouchStart: React.TouchEventHandler<HTMLDivElement> = (e) => {
    setStartPos({ x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY });
    e.stopPropagation();
  }

  const TouchMove:  React.TouchEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setTouchPos({x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY});
    const elements = document.getElementsByClassName(styles['pop-up']);
    if (startPos.y - touchPos.y < 0)
      (elements[0] as HTMLDivElement).style.transform = 'translate3d(0,' + -(startPos.y - touchPos.y) + 'px , 0)';

  }

  const TouchEnd: React.TouchEventHandler<HTMLDivElement> = (e) => {
    let d = {x: startPos.x - touchPos.x, y: startPos.y - touchPos.y};
    e.stopPropagation();
    const elements = document.getElementsByClassName(styles['pop-up']);
    if  ( Math.abs(d.y) > 120 )
    {
      if  ( d.y > 0) {
      }
      else {
        (elements[0] as HTMLDivElement).style.transform = 'translate3d(0, 600px, 0)';
        setTimeout(() => onOuterClick && onOuterClick(), 200);
      }
    }
    else {
      (elements[0] as HTMLDivElement).style.transform = 'translate3d(0, 0, 0)';
    }
  }

  return (
    <PopUpBackground
      unmountOnClose={unmountOnClose}
      onClick={onOuterClick}
      visible={visible}
      withBlur={withBlur}
      className={popUpBackgroundClassName}
    >
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
        className={ cn([styles['pop-up'], popUpClassName]) }
      >
        <div
          onTouchStart={TouchStart}
          onTouchMove={TouchMove}
          onTouchEnd={TouchEnd}
          className={styles.swipeZone}>
          <div className={styles.swipeLine}/>
        </div>
        <div className={styles.header}>
          <div>{ title }</div>
          <div className={styles['ic-close']}>
            {/*<ReactSVG src="" onClick={() => setModalOpened(false)} />*/}
            <IconClose onClick={() => onOuterClick && onOuterClick()}/>
          </div>
        </div>
        {children}
      </div>
    </PopUpBackground>
  );
};
