import React from 'react';
import {IModuleDTO} from '../../../api/systemPreferences/models/IModuleDTO';
import {useSelector} from 'react-redux';
import {RootState} from '../../../redux/storeToolkit';
import {HomePageMenuItem} from './HomePageMenuItem';
import styles from './index.module.scss';

const HomePageMenu = () => {
  const availableModules: Array<IModuleDTO> = useSelector((state: RootState) => state.availableModules);

  return (
    <div className={ styles.main }>
      {
        availableModules.map(
          module => {
            if ((module.display !== 'SHOW_ACTIVE') && (module.display !== 'SHOW_INACTIVE'))
              return <React.Fragment key={ module.id || '' }/>;

            return (
              <React.Fragment key={ module.id || '' }>
                <HomePageMenuItem url={module.url || ''} enabled={module.display === 'SHOW_ACTIVE'} label={module.name || ''}/>
                <div className={ styles.separator }>|</div>
              </React.Fragment>
            )
          }
        )
      }
    </div>
  );
};

export { HomePageMenu };