import React from 'react';
import styles from './index.module.scss';

// компонент отображает подсказку-ошибку прямо под обёрнутым компонентом
const WithErrorHint = (props: any) => {
  return (
    <div className={styles.main}>
      {props.children}
      {props.errorHint && <div className={styles.hint}>{props.errorHint}</div>}
    </div>
  );
};

export { WithErrorHint };
