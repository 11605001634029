import {useState} from 'react';
import {Link} from 'react-router-dom';
import {Button} from '../UI-Lib';
import styles from './index.module.scss';

interface IProps {
  status?: number;
  statusTitle?: string
  statusDescription?: string
  authButton?: boolean
  linkToHome?: boolean
}

const StatusError = ({ status, statusTitle, statusDescription, authButton, linkToHome }: IProps) => {

  const [imageError, setImageError] = useState(false);

  return (
    <div className={ styles.main }>
      <div className={ styles.container }>
        <div className={ styles.status }>
          {
            !imageError
            ? <img
                src={`/img/cats/cat${status}.png`}
                onError={(e: any) => {
                  e.target.onError = null;
                  setImageError(true);
                }}
                alt=''
              />
            : <img src='/img/cats/catError.png' alt=''/>
          }
        </div>

        <div className={ styles.title }>
          {
            // заголовок ошибки
            // если он не передан, то один из заранее предложенных
            statusTitle ||
            (status===400 && 'Неверный запрос') ||
            (status===401 && 'Требуется авторизация') ||
            (status===403 && 'Доступ запрещен') ||
            (status===404 && 'Страница не найдена') ||
            (status===500 && 'Неизвестная ошибка') ||
            (status===503 && 'Сервис недоступен') ||
            'Что-то пошло не так'
          }
        </div>

        <div className={ styles.description }>
          {
            // описание ошибки
            // если оно не передано? то одно из типовых
            statusDescription ||
            (status===400 && 'Кажется, что-то пошло не так') ||
            (status===401 && 'Для дальнейшей работы требуется войти в систему') ||
            (status===403 && 'К сожалению, у Вас нет доступа к этой странице') ||
            (status===404 && 'Она была удалена либо вообще не существует на сайте') ||
            (status===500 && 'Кажется, что-то пошло не так') ||
            (status===503 && 'К сожалению, сервис сейчас недоступен') ||
            'Попробуйте обновить страницу'
          }
        </div>
        {
          (authButton || (authButton===undefined && status===401)) &&
            <div className={ styles.btn_auth }>
              <Link to='/auth'>
                <Button type='primary'>
                  Авторизация
                </Button>
              </Link>
            </div>
        }
        {
          linkToHome &&
            <div className={ styles.link_to_home }>
              <Link to='/'>
                Вернуться на главную
              </Link>
            </div>
        }
      </div>
    </div>
  )
};

export { StatusError };
