import React, { useState, useRef } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

interface Props {
  value: string;
  className?: string;
}

export const CopyLink = ({ value, className }: Props) => {
  const ref = useRef(null);
  const [hasCopied, setHasCopied] = useState<boolean>(false);
  return (
    <div className={`copy-link ${className ? className : ''}`}>
      <div className="copy-link__link-field">
        <div
          className="copy-link__link-value"
          ref={ref}
          onClick={(e) => {
            if (ref) {
              const target: any = ref.current;
              let range: any;
              let selection: any;
              if (document.createRange) {
                range = document.createRange();
                range.selectNode(target);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
              }
            }
          }}
        >
          {value}
        </div>
        <CopyToClipboard text={value} onCopy={() => setHasCopied(true)}>
          <svg className="copy-link__copy-icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 0.75H3C2.175 0.75 1.5 1.425 1.5 2.25V12.75H3V2.25H12V0.75ZM14.25 3.75H6C5.175 3.75 4.5 4.425 4.5 5.25V15.75C4.5 16.575 5.175 17.25 6 17.25H14.25C15.075 17.25 15.75 16.575 15.75 15.75V5.25C15.75 4.425 15.075 3.75 14.25 3.75ZM14.25 15.75H6V5.25H14.25V15.75Z"
              fill="#525252"
            />
          </svg>
        </CopyToClipboard>
      </div>

      <CopyToClipboard text={value} onCopy={() => setHasCopied(true)}>
        <button className={`copy-link__button ${hasCopied ? 'copy-link__button_pressed' : ''}`}>
          {hasCopied ? (
            <svg
              className="copy-link__copied-icon"
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.25109 12.1274L4.12359 8.99988L3.05859 10.0574L7.25109 14.2499L16.2511 5.24988L15.1936 4.19238L7.25109 12.1274Z"
                fill="white"
              />
            </svg>
          ) : (
            ''
          )}
          {hasCopied ? 'Ссылка скопирована' : 'Скопировать ссылку'}
        </button>
      </CopyToClipboard>
    </div>
  );
};
