import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import {setNotificationsScreenOpen} from "../../../../redux/slices/initiative";
import {ReactComponent as Close} from "img/icClose.svg";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/storeToolkit";
import cn from "classnames";
import {useAlert} from "react-alert";
import {NotificationsCategories} from "../../../../models/notifications/INotificationsDTO";
import {Button} from "../../../UI-Lib";
import {NotificationCard} from "./NotificationCard";
import {NotificationPlaceholder} from "./NotificationPlaceholder";
import {useGetNotificationsQuery, useReadNotificationByIdMutation, useReadNotificationsByCategoryMutation} from "../../../../redux/queries/Notifications/notifications.api";
import Skeleton from 'react-loading-skeleton';

const InitiativeNotificationsPanel = () => {

	// const alert = useAlert();
	const dispatch = useDispatch();
	const open = useSelector((state: RootState) => state.initiative.isNotificationsScreenOpen);
	const isAuth = useSelector((state: RootState) => state.auth.role) === 'REGISTERED';

	const [currentCategory, setCurrentCategory] = useState<NotificationsCategories>("ALL");

	const {data: NotificationsData, isLoading: isLoadingNotificationsData, refetch: refetchNofications} = useGetNotificationsQuery({category: currentCategory}, {pollingInterval: 30000});
	const [readNotificationById] = useReadNotificationByIdMutation();
	const [readNotificationsByCategory] = useReadNotificationsByCategoryMutation()

	const closePanel = () => {
		dispatch(setNotificationsScreenOpen(false))
	}

	useEffect( () => {
		let isMounted = true;
		if (isMounted && isAuth) {
			refetchNofications()
		}
		return () => {
			isMounted = false
		};
	}, [currentCategory])

	return (
		<>
			{open && <div className={styles.background} onClick={closePanel}></div>}
			<div className={cn([styles.main, open && styles.open])}>
				<div className={styles.head}>
					<div className={styles.title}>
						<div className={styles['text-header']}>Мониторинг своих инициатив</div>
						<div className={styles.closeBtn} onClick={closePanel}>
							<Close/>
						</div>
					</div>
					<div className={styles.categories}>
						{
							NotificationsData && NotificationsData.categoriesInfo.map((category) =>
								<div onClick={() => setCurrentCategory(category.name)} key={category.name} className={cn([styles.category, category.name === currentCategory && styles.current])}>
									<span className={styles.name}>{category.description}</span>
									{
										category.unreadCount !== 0 && <div className={styles.count}>{category.unreadCount}</div>
									}
								</div>
							)
						}
					</div>
					<div className={styles.btns}>
						<Button type='no-fill' onClick={() => readNotificationsByCategory(currentCategory)}>Отметить все прочитанным</Button>
					</div>
				</div>
				{
					// Если идет загрузка карточек, мы показываем скелетоны
					isLoadingNotificationsData ?
						<div className={styles.notifications}>
							<Skeleton style={{height: '100px', marginBottom: '16px'}} />
							<Skeleton style={{height: '100px', marginBottom: '16px'}} />
							<Skeleton style={{height: '100px', marginBottom: '16px'}} />
							<Skeleton style={{height: '100px', marginBottom: '16px'}} />
						</div>
						:
						<div className={styles.notifications}>
							{
								NotificationsData && NotificationsData.notificationMessages.length ?
									NotificationsData.notificationMessages.map((n) => <NotificationCard readNotification={readNotificationById} key={n.id} notification={n} />)
									:
									// Если после загрузки нет карточек - показываем плэйсхолдер
									<NotificationPlaceholder/>
							}
						</div>
				}
			</div>
		</>
	);
};

export {InitiativeNotificationsPanel};