import React, { useState, useEffect } from 'react';
import { IDateRestrictions } from '../';
import styles from './index.module.scss';
import cn from 'classnames';

interface Props {
  value: Date | undefined;
  onChange: (value: Date | undefined) => void;
  visible?: boolean;
  restrictions?: IDateRestrictions;
}

interface IDayOfMonth {
  content: number | null;
}

export const DatePicker = ({ value, visible = false, onChange, restrictions }: Props) => {
  const [currentYear, setCurrentYear] = useState<number>(value ? value.getFullYear() : new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState<number>(value ? value.getMonth() : new Date().getMonth());
  const [currentDate, setCurrentDate] = useState<number | undefined>(value ? value.getDate() : undefined);

  const [tabNumber, setTabNumber] = useState<number>(0);
  useEffect(() => {
    if (value) {
      setCurrentYear(value.getFullYear());
      setCurrentMonth(value.getMonth());
      setCurrentDate(value.getDate());
    }
  }, [visible]);

  useEffect(() => {
    if (value) {
      const date = new Date(value.toString());
      date.setDate(currentDate || new Date().getDate());
      date.setMonth(currentMonth);
      date.setFullYear(currentYear);
      onChange(date);
    } else {
      if (currentDate) {
          const date = new Date(currentYear, currentMonth, currentDate, 12, 0);
          onChange(date);
      }
    }
  }, [currentDate]);

  const createDaysOfMonth = (year: number, month: number): Array<IDayOfMonth> => {
    // вычисляем количество дней в месяце
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    // создаем первый день в месяце
    const firstDay = new Date(year, month, 1);
    // узнаем индекс дня недели
    // т.к. getDay() возвращает индекс дня, при котором воскресенье имеет индекс 0,
    // то мы делаем искусственное смещение индекса, чтоб дни нумеровались с 1 по 7, начиная с понедельника
    const firstDayOfWeek = (firstDay.getDay() + 6) % 7;
    // инициализируем массив дней недели
    const daysOfMonth: Array<IDayOfMonth> = [];
    // наполняем пустыми днями до первого дня недели
    for (let i = 0; i < firstDayOfWeek; i++) {
      daysOfMonth.push({
        content: null,
      });
    }
    // наполняем днями из месяца
    for (let i = 1; i <= daysInMonth; i++) {
      daysOfMonth.push({
        content: i,
      });
    }
    return daysOfMonth;
  };

  const MONTHES: Array<string> = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',];
  const DAYS_OF_WEEK: Array<string> = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
  const DAYS_OF_MONTH: Array<IDayOfMonth> = createDaysOfMonth(currentYear, currentMonth);

  const YEARS = [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040];

  const decreaseMonth = () => {
    const month = currentMonth - 1;
    if (month === -1) {
      setCurrentYear(currentYear - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth(month);
    }
    if (tabNumber !== 0) {
      setTabNumber(0);
    }
  };

  const increaseMonth = () => {
    const month = currentMonth + 1;
    if (month === 12) {
      setCurrentYear(currentYear + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth(month);
    }
    if (tabNumber !== 0) {
      setTabNumber(0);
    }
  };

  const goToCurrentDate = () => {
    const date = new Date();
    setCurrentYear(date.getFullYear());
    setCurrentMonth(date.getMonth());
    setCurrentDate(date.getDate());
  };

  const isDayDisabled = (day: number | null) => {
    if (day && restrictions) {
      const dateLess = new Date(currentYear, currentMonth, day, 0, 0, 0, 0);
      const dateMore = new Date(currentYear, currentMonth, day, 23, 59, 59, 999);
      if (restrictions.noLessThan && +dateMore - +restrictions.noLessThan < 0) {
        return true;
      }
      if (restrictions.noMoreThan && +dateLess - +restrictions.noMoreThan > 0) {
        return true;
      }
      if (restrictions.noPrev) {
        if (+dateMore.getFullYear() - +new Date().getFullYear() < 0) {
          return true;
        }
        if (+dateMore.getFullYear() - +new Date().getFullYear() === 0 && +dateMore.getMonth() - +new Date().getMonth() < 0) {
          return true;
        }
        if (
          +dateMore.getFullYear() - +new Date().getFullYear() === 0 &&
          +dateMore.getMonth() - +new Date().getMonth() === 0 &&
          +dateMore.getDate() - +new Date().getDate() < 0
        ) {
          return true;
        }
      }
      if (restrictions.noNext) {
        if (+dateLess.getFullYear() - +new Date().getFullYear() > 0) {
          return true;
        }
        if (+dateLess.getFullYear() - +new Date().getFullYear() === 0 && +dateLess.getMonth() - +new Date().getMonth() > 0) {
          return true;
        }
        if (
          +dateLess.getFullYear() - +new Date().getFullYear() === 0 &&
          +dateLess.getMonth() - +new Date().getMonth() === 0 &&
          +dateLess.getDate() - +new Date().getDate() > 0
        ) {
          return true;
        }
      }
      return false;
    }
    return false;
  };

  return (
    <div className={styles.main}>
      <div className={styles['container-header']}>
        <div
          className={styles['header-date-container']}
          onClick={() => {
            if (tabNumber === 0) {
              setTabNumber(1);
            } else {
              setTabNumber(0);
            }
          }}
        >
          <span className={styles['header-month']}>{MONTHES[currentMonth]}</span>
          <span className={styles['header-year']}>{currentYear}</span>
          {tabNumber === 0 ? (
            <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0L4 4L8 0H0Z" />
            </svg>
          ) : (
            <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 4L4 0L0 4L8 4Z" />
            </svg>
          )}
        </div>
        <div className={styles['controls-container']}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles['left-icon']}
            onClick={decreaseMonth}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.0647 4.44446L13.3333 5.61946L9.21269 9.44446L13.3333 13.2695L12.0647 14.4445L6.66658 9.44446L12.0647 4.44446Z"
              fill="#757575"
            />
          </svg>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles['right-icon']}
            onClick={increaseMonth}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.9353 4.44446L6.66675 5.61946L10.7873 9.44446L6.66675 13.2695L7.9353 14.4445L13.3334 9.44446L7.9353 4.44446Z"
              fill="#757575"
            />
          </svg>
        </div>
      </div>

      {tabNumber === 0 && (
        <>
          <div className={styles['days-of-week-container']}>
            {DAYS_OF_WEEK.map((dayOfWeek, index) => (
              <div className={styles['day-of-week']} key={dayOfWeek + index}>
                {dayOfWeek}
              </div>
            ))}
          </div>
          <div className={styles['month-days-container']}>
            {DAYS_OF_MONTH.map(({ content }, index) => {
              return (
                <button
                  key={String(content) + index}
                  disabled={isDayDisabled(content)}
                  onClick={
                    content
                      ? (e) => {
                          setCurrentDate(content);
                        }
                      : undefined
                  }
                  className={cn(
                    styles.day,
                    !content && styles['day-no-content'],
                    value &&
                      currentYear === value.getFullYear() &&
                      currentMonth === value.getMonth() &&
                      content === currentDate &&
                      content === value.getDate() &&
                      styles['day-active'],
                    new Date().getMonth() === currentMonth &&
                      new Date().getFullYear() === currentYear &&
                      new Date().getDate() === content &&
                      styles['day_current']
                  )}
                  // className={`date-picker__day ${
                  //   content ? "" : "date-picker__day_no-content"
                  // } ${
                  //   value &&
                  //   currentYear === value.getFullYear() &&
                  //   currentMonth === value.getMonth() &&
                  //   content === currentDate &&
                  //   content === value.getDate()
                  //     ? "date-picker__day_active"
                  //     : ""
                  // } ${
                  //   new Date().getMonth() === currentMonth &&
                  //   new Date().getFullYear() === currentYear &&
                  //   new Date().getDate() === content
                  //     ? "date-picker__day_current"
                  //     : ""
                  // }`}
                >
                  {content ? content : ''}
                </button>
              );
            })}
          </div>
          <div className={styles['go-to-current-date']} onClick={goToCurrentDate}>
            К текущей дате
          </div>
        </>
      )}
      {tabNumber === 1 && (
        <div className={styles['month-container']}>
          {MONTHES.map((month, index) => {
            return (
              <div
                key={month}
                className={cn(
                  styles.month,
                  index === new Date().getMonth() && styles['month-current'],
                  index === currentMonth && styles['month-active']
                )}
                // className={`date-picker__month ${
                //   index === new Date().getMonth()
                //     ? "date-picker__month_current"
                //     : ""
                // } ${index === currentMonth ? "date-picker__month_active" : ""}`}
                onClick={() => {
                  setCurrentMonth(index);
                  setTabNumber(2);
                }}
              >
                {month}
              </div>
            );
          })}
        </div>
      )}
      {tabNumber === 2 && (
        <div className={styles['years-container']}>
          {YEARS.map((year, index) => {
            return (
              <div
                className={cn(
                  styles.year,
                  year === new Date().getFullYear() && styles['year-current'],
                  year === currentYear && styles['year-active']
                )}
                // className={`date-picker__year ${
                //   year === new Date().getFullYear()
                //     ? "date-picker__year_current"
                //     : ""
                // } ${year === currentYear ? "date-picker__year_active" : ""}`}
                onClick={() => {
                  setCurrentYear(year);
                  setTabNumber(0);
                }}
              >
                {year}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
