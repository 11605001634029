import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IProjectSlice} from './IProjectSlice';
import {IProjectDTO} from '../../../models/projects/project/IProjectDTO';
import {IActivityInProjectDTO} from '../../../models/projects/activities/IActivityInProjectDTO';
import {IIdeasDTO} from '../../../models/projects/ideas/IIdeasDTO';
import {IDocumentSchemaDTO} from '../../../models/projects/ideaSchema/IDocumentSchemaDTO';
import {IIdeaDTO} from '../../../models/projects/ideas/idea/IIdeaDTO';
import {ideaWithNewCalcs} from '../../../utils';
import {IIdeasCapability} from '../../../models/projects/ideas/IIdeasCapability';
import {EUserRole} from '../../../models/projects/project/EUserRole';
import {
  IExpertizeManagementAreaElement
} from '../../../models/documents/expertize/management/area/IExpertizeManagementAreaElement';
import {IResponsibilityZoneDTO} from '../../../models/documents/responsibilityZone/IResponsibilityZoneDTO';
import {IResponsibilityZoneEntry} from '../../../models/documents/responsibilityZone/IResponsibilityZoneEntry';
import {
  ISearchIdeasByProjectFilterParamsDTO
} from '../../../models/projects/ideas/SearchIdeasByProject/ISearchIdeasByProjectFilterParamsDTO';
import {IPageInfo} from './IPageInfo';


const projectSlice = createSlice({
  name: 'project',
  initialState: {
    projectData: {},
    activities: [],
    ideas: {
      capabilities: [],
      ideasList: []
    },
    filters: {},
    myOnlyfilter: false,
    pageInfo: {
      pageNumber: 0,
      countOfElements: 10
    },
    drafts: {
      capabilities: [],
      draftsList: []
    },
    ideaSchema: {},
    role: EUserRole.participant,
    expertizeManagementArea: [],
    responsibilityZones: {
      processes: {}
    }
  } as IProjectSlice,
  reducers: {
    /**
     * записать данные проекта
     * @param state
     * @param action
     */
    setProjectData(state: IProjectSlice, action: PayloadAction<IProjectDTO>) {
      state.projectData = {
        ...state.projectData,
        ...action.payload
      }
    },

    /**
     * записать активности проекта
     * @param state
     * @param action
     */
    setActivities(state: IProjectSlice, action: PayloadAction<Array<IActivityInProjectDTO>>) {
      state.activities = [ ...action.payload ]
    },

    /**
     * записать идеи проекта
     * @param state
     * @param action
     */
    setIdeas(state: IProjectSlice, action: PayloadAction<IIdeasDTO>) {
      state.ideas = {
        capabilities: [...(action.payload.capabilities || [])],
        ideasList: [
          ...(action.payload.content?.ideas?.content || []).map(
            idea => ideaWithNewCalcs(idea)
          )
        ]
      }
    },

    /**
     * обновить список идей
     * @param state
     * @param action
     */
    updateIdeasList(state: IProjectSlice, action: PayloadAction<Array<IIdeaDTO>>) {
      state.ideas = {
        ...state.ideas,
        ideasList: [
          ...action.payload.map( // для поддержки старых идей
            idea => ideaWithNewCalcs(idea)
          )
        ]
      }
    },

    /**
     * установить фильтры
     * @param state
     * @param action
     */
    setIdeasFilters(state: IProjectSlice, action: PayloadAction<ISearchIdeasByProjectFilterParamsDTO>) {
      state.filters = action.payload
    },

    /**
     * установить фильтр ТОЛЬКО МОИ
     * @param state
     * @param action
     */
    setMyOnlyfilter(state: IProjectSlice, action: PayloadAction<boolean>) {
      state.myOnlyfilter = action.payload
    },
    

    /**
     * установить состояние постраничности
     * @param state
     * @param action
     */
    setPageInfo(state: IProjectSlice, action: PayloadAction<IPageInfo>) {
      state.pageInfo = action.payload
    },

    /**
     * обновить список разрешений
     * @param state
     * @param action
     */
    updateIdeasCapabilities(state: IProjectSlice, action: PayloadAction<Array<IIdeasCapability>>) {
      state.ideas = {
        ...state.ideas,
        capabilities: [...action.payload]
      }
    },

    /**
     * записать черновики проекта
     * @param state
     * @param action
     */
    setDrafts(state: IProjectSlice, action: PayloadAction<IIdeasDTO>) {
      state.drafts = {
        capabilities: [ ...(action.payload.capabilities || []) ],
        draftsList: [ ...(action.payload.content?.ideaDrafts?.content || []).map(draft => ideaWithNewCalcs(draft)) ]
      }
    },

    /**
     * обновить список черновиков
     * @param state
     * @param action
     */
    updateDraftsList(state: IProjectSlice, action: PayloadAction<Array<IIdeaDTO>>) {
      state.drafts = {
        ...state.drafts,
        draftsList: [ ...action.payload.map(draft => ideaWithNewCalcs(draft)) ]
      }
    },

    /**
     * записать схему идеи проекта
     * @param state
     * @param action
     */
    setIdeaSchema(state: IProjectSlice, action: PayloadAction<IDocumentSchemaDTO>) {
      state.ideaSchema = { ...action.payload }
    },

    /**
     * обновить идею в списке
     * @param state
     * @param action
     */
    updateIdeaInList(state: IProjectSlice, action: PayloadAction<IIdeaDTO>) {
      state.ideas = {
        ...state.ideas,
        ideasList: (state.ideas?.ideasList || []).map(
          (idea: IIdeaDTO) => idea.id === action.payload.id
            ? ideaWithNewCalcs(action.payload)
            : idea
        )
      }
    },

    /**
     * обновить черновик в списке
     * @param state
     * @param action
     */
    updateDraftInList(state: IProjectSlice, action: PayloadAction<IIdeaDTO>) {
      state.drafts = {
        ...state.drafts,
        draftsList: (state.drafts?.draftsList || []).map(
          (draft: IIdeaDTO) => draft.id === action.payload.id
            ? ideaWithNewCalcs(action.payload)
            : draft
        )
      }
    },

    /**
     * удалить черновик из списка
     * @param state
     * @param action
     */
    deleteDraftFromList(state: IProjectSlice, action: PayloadAction<string>) {
      state.drafts = {
        ...state.drafts,
        draftsList: (state.drafts?.draftsList || [])
          .filter( (draft: IIdeaDTO) => draft.id !== action.payload )
      }
    },

    setRole(state: IProjectSlice, action: PayloadAction<EUserRole>) {
      state.role = action.payload
    },

    setExpertizeManagementArea(state: IProjectSlice, action: PayloadAction<Array<IExpertizeManagementAreaElement>>) {
      state.expertizeManagementArea = [...action.payload]
    },

    /**
     * работа с зонами ответственности
     */
    /**
     * записать всю зону
     * @param state
     * @param action
     */
    setResponsibilityZoneProcesses(state: IProjectSlice, action: PayloadAction<IResponsibilityZoneDTO>) {
      state.responsibilityZones.processes = action.payload
    },

    /**
     * обновление одной записи
     * @param state
     * @param action
     */
    updateResponsibilityZoneProcess(state: IProjectSlice, action: PayloadAction<IResponsibilityZoneEntry>) {
      state.responsibilityZones.processes = {
        ...state.responsibilityZones.processes,
        entries: (state.responsibilityZones.processes?.entries || []).map(
          entry => entry.id === action.payload.id ? action.payload : entry
        )
      }
    }
  }
})

export const {
  setProjectData,
  setActivities,
  setIdeas,
  updateIdeasList,
  setIdeasFilters,
  setMyOnlyfilter,
  setPageInfo,
  updateIdeasCapabilities,
  setDrafts,
  updateDraftsList,
  setIdeaSchema,
  updateIdeaInList,
  updateDraftInList,
  deleteDraftFromList,
  setExpertizeManagementArea,
  setResponsibilityZoneProcesses,
  updateResponsibilityZoneProcess
} = projectSlice.actions;

export default projectSlice.reducer;