import React, {useState} from 'react';
import styles from './index.module.scss'
import {ILandingCard} from "../../../../../../models/landings/constructor/ILandingsDTO/ILandingCard";
import {TextField} from "../../../../../UI-Lib/TextField_v2";

interface IProps {
	landing?: ILandingCard
}

const Domain:React.FC<IProps> = ({landing}) => {

	const [domain, setDomain] = useState<string>('')

	return (
		<div className={styles.main}>
			<div className={styles.editDomain}>
				<div className={styles.hint}>Домен</div>
				<TextField value={domain} handleChange={setDomain}/>
			</div>
		</div>
	);
};

export { Domain };