import React from 'react';
import {ReactComponent as NotificationPanelClear} from "../../../../../img/Sberboost/NotificationPanelClear.svg";
import styles from './index.module.scss';

const NotificationPlaceholder = () => {
	return (
		<div className={styles.main}>
			<div className={styles.placeholder}>
				<div className={styles.svg}>
					<NotificationPanelClear/>
				</div>

				<div className={styles.text}>Здесь Пусто</div>
			</div>
		</div>
	);
};

export { NotificationPlaceholder };