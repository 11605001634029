import {Badge} from './Badge';
import {useEffect, useState} from 'react';
import {IBadge} from '../../../models/homepage/IBadge';
import {homePageApi} from '../../../api/homePageApi';
import {Carousel} from '../../UI-Lib/Carousel';
import {Preloader} from '../../Preloader';
import styles from './index.module.scss';

/**
 * область с бейджами
 * @constructor
 */
const Badges = () => {

  const [badgesList, setBadgesList] = useState<Array<IBadge>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      const getBadgesAsync = async () => {
        try {
          setLoading(true);
          setBadgesList((await homePageApi.getBadges()).data);
        } catch (e) {
        } finally {
          setLoading(false);
        }
      };
      getBadgesAsync();
    }
  ,[])

  if (loading)
    return <Preloader/>

  return (
    <div className={ styles.main }>
      <Carousel pageWidth={184}>
        {
          badgesList.map(
            badge => <Badge key={badge.id} badge={ badge }/>
          )
        }
      </Carousel>
    </div>
  );
};

export { Badges };