import { instance } from '../index';
import { TPlatformModulesDto } from './models/PlatformModulesDto';
import { IAvailableLoginTypesDto } from './models/AvailableLoginTypes/IAvailableLoginTypesDto';
import {IInitiativeExpertizeIsAvailable} from './models/IInitiativeExpertizeIsAvailable';
import {ICreateFormTooltips, IFormInformationResponse} from './models/IInitiativeCreateFormInfoDTO';

interface ISystemPreferencesAPI {
  getStatusSurvey: (name: string) => Promise<any>;
  getAvailableModulesOnPlatform: () => Promise<TPlatformModulesDto>;
  getAvailableTypeEntrance: () => Promise<IAvailableLoginTypesDto>;
  getInitiativeExpertizeIsAvailable: () => Promise<IInitiativeExpertizeIsAvailable>;
  getCreateFormInformation: () => Promise<IFormInformationResponse>;
  getCreateFormTooltips: () => Promise<ICreateFormTooltips>
}

export const systemPreferencesAPI: ISystemPreferencesAPI = {
  getStatusSurvey: (name: string) => {
    return instance
      .get(`/platform-system-preferences/v1.0/preferences/concrete/${name}/surveys`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  // доступные модули на платформе
  getAvailableModulesOnPlatform: () => {
    return instance
      .get(`/platform-system-preferences/v1.0/preferences/concrete/modules/platform`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  // разрешён ли вход по логину/паролю
  getAvailableTypeEntrance: () => {
    return instance
      .get(`/platform-system-preferences/v1.0/preferences/concrete/authorization/platform`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  // разрешена ли экспертиза
  getInitiativeExpertizeIsAvailable: () => {
    return instance
      .get('/platform-system-preferences/v1.0/preferences/concrete/sberboost/expertize')
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  // getCreateFormInformation
  getCreateFormInformation: () => {
    return instance
      .get('/platform-system-preferences/v1.0/preferences/concrete/create_form_switch/sberboost')
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  // получение подсказок для формы подачи
  getCreateFormTooltips: () => {
    return instance
      .get('/platform-system-preferences/v1.0/preferences/concrete/create_form_v20/sberboost')
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}