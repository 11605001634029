import { instance } from '../index';
import { ISurveyCampaignDto } from '../../models/surveys/ISurveyCampaignDto';
import { ISurveyCampaignDto_v3 } from '../../models/surveys/Campaigns/ISurveyCampaignDto_v3';
import { ISurveyCampaignDto_v3_blocks } from '../../models/surveys/Campaigns/ISurveyCampaignDto_v3_blocks';
import {ISurveyDTO} from '../../models/surveys/Survey/ISurveyDTO';
import {AxiosResponse} from 'axios';
import {IAnswerForServer} from '../../models/surveys/Survey/Question/IAnswerForServer';
import {IGroupList} from '../../models/surveys/Survey/IGroupList';
import {ISession} from '../../models/surveys/ISession';
import {IDemoInformation} from '../../models/surveys/Campaigns/IDemoInformation';
import {IChangeAccessRuleParams} from '../../models/surveys/Survey/IChangeAccessRuleParams';
import {ISurveyProgress} from '../../models/surveys/Survey/ISurveyProgress';
import {IManualFromServer} from '../../models/surveys/Survey/IManualFromServer';
import {INewStepDTO} from "../../redux/slices/gvk/IStepDTO";

interface ISurveysAPI {
  // получение статуса пройденнности опросов
  getSurveyProgress: (campaign: string) => Promise<Array<ISurveyProgress>>;
  // изменение параметров демо блока для опроса
  changeAccessRule: (params: IChangeAccessRuleParams) => Promise<number | null>;
  // получение всех доступных опросов для сраницы админки
  getAllSurveysADM: () => Promise<number | null>;
  // получение всех доступных опросов
  getAllSurveys: (demoParams: string) => Promise<IGroupList>;
  // получение всех доступных для пользователя опросов по его токену
  getAllSurveysForCurrentUser: () => Promise<IGroupList>;
  // получение списка опросов в старой версии от Фахри
  getAllSurveysByCampaign: (campaignId: string) => Promise<any>;
  // получение всех доступных опросов из апи кампаний
  getAllSurveysByCampaign_v2: (campaignId: string) => Promise<ISurveyCampaignDto>;
  // получение структуры кампании v3
  getSurveyCampaign_v3: (campaignId: string) => Promise<ISurveyCampaignDto_v3>;
  // получение структуры кампании v3 в БЛОКАХ
  getSurveyCampaign_v3_blocks: (campaignId: string) => Promise<ISurveyCampaignDto_v3_blocks>;
  // получение структуры опроса по его ID из кампании
  // getSurveyByCampaign: (campaign: string, surveyInstanceId: string) => Promise<ISurveyData>;
  // получение структуры опроса по его ID
  // getSurvey: (id: string) => Promise<ISurveyData>;
  // получение структуры опроса по его ID из кампании v3
  getSurveyByCampaign_v3: (campaign: string, surveyInstanceId: string) => Promise<AxiosResponse<ISurveyDTO>>;
  // получение структуры опроса по его ID v3
  getSurvey_v3: (id: string) => Promise<AxiosResponse<ISurveyDTO>>;
  // отправка ответа на вопрос
  postAnswer: (answerJSON: string) => Promise<number | null>;
  // отправка ответа на вопрос v2
  sendAnswer: (campaign: string, answer: IAnswerForServer) => Promise<string>;
  // отправка завершения или отмены прохождения опроса
  postSurveyConfirm: (campaign: string, surveyConfirmationJSON: string) => Promise<number | null>;
  // замена опроса в кампании
  updateSurvey: (newSurvey: string, surveyInstanceId: string) => Promise<number | null>;
  // замена простого опроса
  updateSimpleSurvey: (newSurvey: string, surveyInstanceId: string) => Promise<number | null>;

  // запрос справочника для наполнения выпадающего списка
  getManual: (dictionary_name: string) => Promise<IManualFromServer>;

  // запрос справочника с параметрами
  getManualByFilter: (dictionary_name: string, filters: Array<{ fieldValue: string; source: string }>) => Promise<IManualFromServer>;

  // сохранение параметров демо-блока
  saveDemoParams: (campaign: string, demoParams: { parameters: Array<{ name: string; value: string }> }) => Promise<void>;

  // открытие сессии
  sessionOpen: (campaign: string) => Promise<ISession>;

  // отправка команд(ы) select/unselect необязательных опросов
  sendSelectCommand: (campaignName: string, commands: Array<any>) => Promise<any>;

  // подтверждение / скип шага НОВОЕ
  confirmCommand: (campaignName: string, stepId: string, newStatus: string) => Promise<any>;

  // создание участия НОВОЕ
  createParticipation: (campaignName: string) => Promise<any>;

  // открытие сессии НОВОЕ
  sessionOpenGVK: (campaignName: string, id: string) => Promise<ISession>;

  // закрытие сессии
  sessionClose: () => Promise<any>;

  // запрос наличия параметров демо-блока
  getStatusDemoParams: (campaignName: string) => Promise<IDemoInformation>;

  // запрос актуальных параметров демо-блока
  getActualDemoParams: () => Promise<any>;

  // актуализация шаблонных параметров демо-блока пользователя
  setActualize: () => Promise<any>;

  // *** запрос, который возвращает группы (кампании) опросов (тестовый)
  getSurveysCampaignsTest: () => Promise<any>;

  // *** запрос, который возвращает группы (кампании) опросов
  getSurveysCampaigns: () => Promise<any>;

  // Получение нового идентификатора прохождения опроса
  getSurveyTaking: (surveyInstanceId: string) => Promise<any>;

  enterActivityCampaign: (campaignName: string) => Promise<INewStepDTO>;

  moveForwardActivityCampaign: (stepId: string, result: any) => Promise<any>;

  moveForwardWithSkip: (stepId:string, result: any) => Promise<INewStepDTO>;

  moveBackwardActivityCampaign: (stepId: string, result: any) => Promise<any>;
}

export const index: ISurveysAPI = {
  getSurveyProgress(campaign) {
    const config = {
      params: {
        campaign,
      },
    };
    return instance
      .get(`/survey-answers/v1.0/survey-progress`, config)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  changeAccessRule(params: IChangeAccessRuleParams) {
    return instance
      .post(`/campaign-instance-query/v1.0/maintain/access_rule`, params)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getAllSurveysADM() {
    return instance
      .get(`/campaign-instance-query/v1.0/maintain/survey-instance/all`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getAllSurveys(demoParams: string): Promise<IGroupList> {
    return instance
      .get(`/survey-instance-query/v1.0/survey-instance?${demoParams}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getAllSurveysForCurrentUser: () => {
    return instance
      .get('/survey-instance-query/v1.0/survey-instance/available')
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getAllSurveysByCampaign: (campaignId) => {
    const config = {
      params: {
        chapterName: campaignId,
        campaign: campaignId,
      },
    };
    return (
      instance
        .get('/campaign-instance-query/v1.0/survey-instance/available/by-chapter-name', config)
        // return instance.get('/campaign-instance-query/v1.0/survey-instance/available', config)
        .then((response) => response.data)
        .catch((error) => {
          throw error;
        })
    );
  },

  getAllSurveysByCampaign_v2: (campaignId) => {
    const config = {
      params: {
        //chapterName: campaignId,
        campaign: campaignId,
      },
    };
    // return instance.get('/campaign-instance-query/v1.0/survey-instance/available/by-chapter-name', config)
    return instance
      .get('/campaign-instance-query/v1.0/survey-instance/available', config)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getSurveyCampaign_v3: (campaignId) => {
    return instance
      .get(`/campaign-instance-query/v1.0/survey-dictionary/campaign/${campaignId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getSurveyCampaign_v3_blocks: (campaignId) => {
    return instance
      .get(`/campaign-instance-query/v2.0/survey-dictionary/campaign/${campaignId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  // getSurveyByCampaign: (campaign, surveyInstanceId) => {
  //   const config = {
  //     params: {
  //       campaign,
  //     },
  //   };
  //   return instance
  //     .get(`/campaign-instance-query/v1.0/survey-instance/available/schema/${surveyInstanceId}`, config)
  //     .then((response) => response.data)
  //     .catch((error) => {
  //       throw error;
  //     });
  // },

  // getSurvey(id: string): Promise<ISurveyData> {
  //   return instance
  //     .get(`/survey-instance-query/v1.0/survey-instance/available/schema/${id}`)
  //     .then((response) => response.data)
  //     .catch((error) => {
  //       throw error;
  //     });
  // },

  getSurveyByCampaign_v3: (campaign, surveyInstanceId) => {
    const config = {
      params: {
        campaign,
      },
    };
    return instance.get(`/campaign-instance-query/v1.0/survey-instance/available/schema/${surveyInstanceId}`, config);
  },

  getSurvey_v3: (id: string) => {
    return instance
      .get(`/survey-instance-query/v1.0/survey-instance/available/schema/${id}`);
  },

  postAnswer(answerJSON: string): Promise<number | null> {
    return instance
      .post('/survey-answers/v1.0/survey-answers', answerJSON)
      .then((response) => {
        if (response.status) return response.status;
        else return -1;
      })
      .catch((error) => {
        throw error;
      });
  },

  sendAnswer: (campaign, answer) => {
    const config = {
      params: {
        campaign,
      },
    };
    return instance
      .post('/survey-answers/v1.0/survey-answers', answer, config)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  postSurveyConfirm(campaign, surveyConfirmationJSON): Promise<number | null> {
    const config = {
      params: {
        campaign,
      },
    };
    return instance
      .post('/survey-answers/v1.0/confirmation', surveyConfirmationJSON, config)
      .then((response) => {
        if (response.status) return response.status;
        else return -1;
      })
      .catch((error) => {
        throw error;
      });
  },

  updateSurvey(newSurvey: string, surveyInstanceId: string): Promise<number | null> {
    return instance.put(`/campaign-instance-query/v1.0/maintain/survey-instance/${surveyInstanceId}`, newSurvey).then((response) => {
      // window.location.reload()
      if (response.status) return response.status;
      else return -1;
    });
  },

  updateSimpleSurvey(newSurvey: string, surveyInstanceId: string): Promise<number | null> {
    return instance.put(`/survey-instance-query/survey-instance-maintain/${surveyInstanceId}`, newSurvey).then((response) => {
      // window.location.reload()
      if (response.status) return response.status;
      else return -1;
    });
  },

  getManual(dictionary_name: string): Promise<IManualFromServer> {
    const body = {
      params: {
        dictionary_name,
      },
    };
    return instance
      .get(`/survey-demo-block-dictionary/v1.0/dictionary_value`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  // запрос справочника с параметрами
  getManualByFilter: (dictionary_name: string, filters: Array<{ fieldValue: string; source: string }>) => {
    const body = {
      filters,
    };
    return instance
      .post(`/survey-demo-block-dictionary/v1.0/dictionary_value/mixed-filter?dictionary_name=${dictionary_name}`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  saveDemoParams(campaign, demoParams): Promise<void> {
    const config = {
      params: {
        campaign,
      },
    };
    return instance
      .post('/demo_parameter_management/v1.0/actual_parameters', demoParams, config)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  sessionOpen: (campaign) => {
    const config = {
      params: {
        campaign,
      },
    };
    return instance
      .post('survey-session/v1.0/session', null, config)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  createParticipation: (campaignName: string) => {
    return instance
      .post(`/campaign-instance-query/v1.0/registration/${campaignName}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      })
  },

  sessionOpenGVK: (campaignName, sessionId) => {
    const config = {
      params: {
        campaignName,
        sessionId
      }};
    return instance
      .post('survey-session/v1.0/session/create', null, config)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  sendSelectCommand: (campaignName, commands) => {
    const body = {commands}
    return instance
      .put(`/campaign-instance-query/v1.0/participation/command/select/${campaignName}`, body)
      .then((response) => response.data)
  },

  confirmCommand: (campaignName,stepId, newStatus) => {
    const body = {
      stepId,
      newStatus
    }
    return instance
      .put(`/campaign-instance-query/v1.0/participation/command/confirm/${campaignName}`, body)
      .then((response) => response.data)
  },

  sessionClose: () => {
    return instance
      .post('survey-session/v1.0/session/close')
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getStatusDemoParams: (campaignName) => {
    const config = {
      params: {
        campaign: campaignName,
      },
    };
    return instance
      .get('/demo_parameter_management/v1.0/actual_demo_block/details', config)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getActualDemoParams: () => {
    return instance
      .get('/demo_parameter_management/v1.0/actual_demo_block')
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  // актуализация шаблонных параметров демо-блока пользователя
  setActualize: () => {
    return instance
      .post('/demo_parameter_management/v1.0/routine_parameters/actualize')
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  // *** запрос, который возвращает группы опросов
  getSurveysCampaignsTest: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          {
            id: 'gvk',
            name: 'ГВК',
            description: 'Голос внутреннего клиента',
            isCampaign: true,
            status: 'STARTED',
            enabled: true,
            url: '?campaign=gvk',
            image: '',
          },
          {
            id: '2',
            name: 'Настроение',
            description: 'Опрос',
            isCampaign: false,
            status: 'STOPPED',
            enabled: true,
            url: '/24479f37-6131-4de8-a14a-c8d269541aa8',
            image: '',
          },
        ]);
      }, 1000);
    });
  },

  // *** запрос, который возвращает группы (кампании) опросов
  getSurveysCampaigns: () => {
    return instance
      .get('/campaign-instance-query/v1.0/campaign-instance/')
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  // Получение нового идентификатора прохождения опроса
  getSurveyTaking: (surveyInstanceId) => {
    const config = {
      params: {
        surveyInstanceId,
      },
    };
    return instance
      .get('/survey-answers/v1.0/survey-taking', config)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  enterActivityCampaign: (campaignName) => {
    return instance
      .post(`/campaign-instance-query/v2.0/activity/enter/${campaignName}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      })
  },

  moveForwardActivityCampaign: (stepId, result) => {
    const body = {
      stepId,
      result
    }
    return instance
      .put(`/campaign-instance-query/v2.0/activity/complete-and-move-forward`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      })
  },

  moveBackwardActivityCampaign: (stepId, result) => {
    const body = {
      stepId,
      result
    }
    return instance
      .put(`/campaign-instance-query/v2.0/activity/move-backward`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      })
  },

  moveForwardWithSkip: (stepId, result) => {
    const body = {
      stepId,
      result
    }
    return instance
      .put(`/campaign-instance-query/v2.0/activity/skip-and-move-forward`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      })
  },

};
