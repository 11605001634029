import React, { useState, useEffect } from 'react';
import {TextField} from '..';
import cn from 'classnames';
import styles from './index.module.scss';

type option = any;

interface Props {
  options: Array<option>;
  component: (option?: option) => JSX.Element;
  setValue: React.Dispatch<React.SetStateAction<option>>;
  value: option;
  checkIfOptionActive?: (option: option, value: option) => boolean;
  className?: string;
  optionToString?: (option: option) => string;
  transformSearchString?: (searchString: string) => string;
  searchFunction?: (option: option, searchString: string) => boolean;
  label?: string;
  placeholder?: string;
  hasSearch?: boolean;
  disabled?: boolean;
  orientationTop?: boolean;
}

export const DropDownWithSearch = ({
  options,
  component,
  value,
  setValue,
  className,
  placeholder,
  label,
  hasSearch = true,
  disabled = false,
  orientationTop = false,
  checkIfOptionActive = (option, value) => {
    return option === value;
  },
  optionToString = (option) => {
    return String(option).toLowerCase();
  },
  transformSearchString = (searchString) => {
    return searchString.toLowerCase();
  },
  searchFunction = (option, searchString) => {
    const searchOption = optionToString(option);
    const searchStringTransformed = transformSearchString(searchString);

    return searchOption.includes(searchStringTransformed);
  },
}: Props) => {
  const [searchInputState, setSearchInputState] = useState<string>('');
  const [searchedOptions, setSearchedOptions] = useState<typeof options>(options);
  const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);

  useEffect(() => {
    setSearchedOptions(
      options.filter((option) => {
        return searchFunction(option, searchInputState);
      })
    );
    //eslint-disable-next-line
  }, [searchInputState, options]);

  const toggleOpen = () => {
    setDropdownOpened((prev) => !prev);
  };

  return (
    <>
      <div className={cn(styles.main, dropdownOpened && styles.opened, className)}>
        <button className={styles.field} onClick={toggleOpen} disabled={disabled}>
          {value ? component(value) : <div className={styles.placeholder}>{placeholder ? placeholder : ''}</div>}
          <div className={styles['toggle-icon-container']}>
            {dropdownOpened ? (
              <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 4L4 0L0 4L8 4Z" fill="#757575" />
              </svg>
            ) : (
              <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L4 4L8 0H0Z" fill="#757575" />
              </svg>
            )}
          </div>
        </button>
        <div className={cn(styles.modal, dropdownOpened && styles['modal-opened'], orientationTop && styles['modal-opened-top'])}>
          {hasSearch ? (
            <div className={styles.search}>
              <TextField
                start_icon={
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.625 10.5H11.0325L10.8225 10.2975C11.5575 9.4425 12 8.3325 12 7.125C12 4.4325 9.8175 2.25 7.125 2.25C4.4325 2.25 2.25 4.4325 2.25 7.125C2.25 9.8175 4.4325 12 7.125 12C8.3325 12 9.4425 11.5575 10.2975 10.8225L10.5 11.0325V11.625L14.25 15.3675L15.3675 14.25L11.625 10.5ZM7.125 10.5C5.2575 10.5 3.75 8.9925 3.75 7.125C3.75 5.2575 5.2575 3.75 7.125 3.75C8.9925 3.75 10.5 5.2575 10.5 7.125C10.5 8.9925 8.9925 10.5 7.125 10.5Z"
                      fill="#757575"
                    />
                  </svg>
                }
                placeholder="Поиск"
                end_icon={
                  searchInputState ? (
                    <svg
                      onClick={() => {
                        setSearchInputState('');
                      }}
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                        fill="#757575"
                      />
                    </svg>
                  ) : (
                    ''
                  )
                }
                value={searchInputState}
                onChange={(e: any) => {
                  setSearchInputState(e.target.value);
                }}
              />
            </div>
          ) : (
            ''
          )}
          {!searchInputState && label ? <div className={styles.label}>{label}</div> : ''}
          <div className={styles.options}>
            {searchedOptions.map((option) => (
              <div
                className={cn(styles.option, checkIfOptionActive(option, value) && styles.active)}
                onClick={() => {
                  setValue(option);
                  setDropdownOpened(false);
                }}
                key={JSON.stringify(option)}
              >
                {component(option)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
