import {Link} from 'react-router-dom';
import {IFavoriteProject} from '../../../../models/homepage/IFavoriteProject';
import styles from './index.module.scss';

interface IProps {
  project: IFavoriteProject
}

const FavoriteProject = ({ project } : IProps) => {
  return (
    <div className={ styles.main }>
      <div className={ styles.content }>
        <div className={ styles.image }>
          <img src={ project.imgUrl || '' } alt=""/>
        </div>

        <div className={ styles.separator }/>

        <div className={ styles.title }>{ project.title }</div>

        <div className={ styles.subtitle }>{ project.subtitle }</div>

        <div className={ styles.description }>{ project.description }</div>
      </div>

      <div className={ styles.action }>
        <Link to={ project.link || '' }>Узнать подробнее</Link>
      </div>
    </div>
  );
};

export { FavoriteProject };