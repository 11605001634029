import {FavoriteProject} from './FavoriteProject';
import {useEffect, useState} from 'react';
import {IFavoriteProject} from '../../../models/homepage/IFavoriteProject';
import {homePageApi} from '../../../api/homePageApi';
import {Preloader} from '../../Preloader';
import {Carousel} from '../../UI-Lib/Carousel';
import {Link} from 'react-router-dom';
import styles from './index.module.scss';

const FavoriteProjects = () => {
  const [favoriteProjects, setFavoriteProjects] = useState<Array<IFavoriteProject>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      const getFavoriteProjectsAsync = async () => {
        try {
          setLoading(true);
          setFavoriteProjects((await homePageApi.getFavoriteProjects()).data);
        } catch (e) {
        } finally {
          setLoading(false);
        }
      }
      getFavoriteProjectsAsync();
    }
  ,[]);

  if (loading)
    return <Preloader/>

  return (
    <div className={ styles.main }>
      <div className={ styles.projects_container }>
        <Carousel pageWidth={396}>
          {
            favoriteProjects.map(
              project => <FavoriteProject key={ project.id } project={ project }/>
            )
          }
        </Carousel>
      </div>

      <div className={ styles.all_projects }>
        <Link to='/legacy/sign-in?target_uri=https://sbidea.ru/projects'>
          Все проекты
        </Link>
      </div>
    </div>
  );
};

export { FavoriteProjects };