import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMeetingsDTO} from "../../models/meetings/IMeetingsDTO";
import {IMeetingDTO} from "../../models/meetings/IMeetingDTO";
import {TPosterCapability} from "../../models/meetings/TPosterCapability";
import {IMeetingsFiltersType} from "../../models/meetings/IFilters";
import {IPosterParamsDTO} from "../../models/meetings/IPosterParamsDTO";
import {IMeetingFormat} from '../../models/meetings/IMeetingDTO/IMeetingFormat';
import {IOrganizer} from '../../models/meetings/IOrganizer';

type IMeetingSlice = {
	features: TPosterCapability[],
	formats: IMeetingFormat[],
	organizers: IOrganizer[],
	meetingsData: IMeetingsDTO,
	currentMeeting: IMeetingDTO,
	currentFilters: IMeetingsFiltersType,
	currentPageable: IPosterParamsDTO,
	isLoading: boolean
}


const meetingsSlice = createSlice({
	name: 'meetings',
	initialState: {
		currentFilters: {	// это актуальные параметры фильтров на странице маркетплейса митингов
			date: undefined,
			format: undefined,
			search: undefined,
			organizer: undefined
		},
		currentPageable: {
			page: 0,
			countOfElements: 9
		},
		isLoading: false,
		features: [],		// возможности(доступы)
		formats: [],		// форматы событий
		organizers: [], 	// организаторы событий (для фильтрации)
		meetingsData: {},	// События + пагинация
		currentMeeting: {}	// Выбранное событие
	} as IMeetingSlice,
	reducers: {
		updateFilters (state, action: PayloadAction<IMeetingsFiltersType>) {
			state.currentFilters = action.payload
		},
		updateDateFilter (state, action: PayloadAction<Date| undefined>) {
			state.currentFilters = {...state.currentFilters, date: action.payload}
		},
		updateFormatFilter (state, action: PayloadAction<string | undefined>) {
			state.currentFilters = {...state.currentFilters, format: action.payload}
		},
		updateOrganizerFilter (state, action: PayloadAction<IOrganizer>) {
			state.currentFilters = {...state.currentFilters, organizer: action.payload}
		},
		updateSearchFilter (state, action: PayloadAction<string | undefined>) {
			state.currentFilters = {...state.currentFilters, search: action.payload}
		},
		updatePageable (state, action: PayloadAction<{page: number, countOfElements: number}>) {
			state.currentPageable = action.payload;
		},
		updateMeetingsData (state, action: PayloadAction<IMeetingsDTO>) {
			state.meetingsData	= action.payload;
		},
		updateIsLoading (state, action: PayloadAction<boolean>) {
			state.isLoading	= action.payload;
		}
	}

});

export const {
	updateFilters,
	updateDateFilter,
	updateFormatFilter,
	updateOrganizerFilter,
	updateSearchFilter,
	updatePageable,
	updateMeetingsData,
	updateIsLoading

} = meetingsSlice.actions

export default meetingsSlice.reducer;