import styles from './index.module.scss';

const Footer = () => {
  const currentYear = (new Date()).getFullYear().toString();
  return (
    <footer className={ styles.main } id='footer'>
      <div className={ styles.container }>
        <div className={ styles.links }>
          <div>
            <a href="https://sberfriend.sbrf.ru/sberfriend/#/interaction/new?elementId=11969" target="_blank" rel="noopener noreferrer">
              Сообщить о проблеме
            </a>
          </div>

          {/* <div>
            <a href="https://sberfriend.sbrf.ru/sberfriend/#/interaction/new?elementId=11969">Помощь</a>
          </div> */}

          {/* <div>
            <a href="https://www.sbidea.ru/contacts">Контакты</a>
          </div> */}
          
          <div>
            <a href="https://www.sberbank.ru/privacy/policy#pdn" target="_blank">Политика конфиденциальности</a>
          </div>

          <div>
            <a href="https://new.sbidea.ru/sberboost/SberBoostRules" target="_blank">Правила работы</a>
          </div>

          
        </div>
        <div className={ styles.address }>
          © 1997—{currentYear} ПАО Сбербанк. Россия, Москва, 117997, ул. Вавилова, 19
          <br />
          Генеральная лицензия на осуществление банковских операций от 11 августа 2015 года. Регистрационный номер — 1481.
          <br />
          <br />
          <div className={ styles.contacts }>
            {/* <div className={ styles.contact }>
              Экстренная{`${'\u00A0'}`}связь:{`${'\u00A0'}`}<a href='tel:8-55747548'>8-55747548</a>
            </div> */}

            <div className={ styles.contact }>
              Почта:{`${'\u00A0'}`}<a href='mailto:helpidea@sberbank.ru'>helpidea@sberbank.ru</a>
            </div>

            {/* <div className={ styles.contact }>
              Разработано{`${'\u00A0'}`}командой{`${'\u00A0'}`}<a href="https://www.sbidea.ru/promo">Сберидея</a>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
