import { Tab } from './Tab';
import cn from 'classnames';
import {ITab} from './ITab';
import styles from './index.module.scss';

interface IProps {
  tabs: Array<ITab>;
  activeTab: string;
  setActiveTab: Function;
  className?: string;
  classNameTab?: string;
}

const TabsContainer = ({ tabs, activeTab, setActiveTab, className, classNameTab }: IProps) => {
  return (
    <div className={cn([styles.main, className])}>
      {tabs.map((tab, i) => (
        <Tab classNameTab={classNameTab} key={tab.id} name={tab.name} counter={tab.count || 0} active={tab.id === activeTab} setActive={() => setActiveTab(tab.id)} />
      ))}
    </div>
  );
};

export { TabsContainer };
