import React from 'react';
import {ILandingVersion} from "../../../../../models/landings/constructor/ILandingsDTO/ILandingVersion";
import styles from './index.module.scss';

interface IProps {
	version: ILandingVersion;
}

const VersionItem:React.FC<IProps> = ({version}) => {

	return (
		<div className={styles.main}>
			<div>
				<div className={styles.status} style={{backgroundColor: version.status === 'ACTIVE' ? "rgba(113, 185, 134, 1)" : "rgba(220, 220, 220, 1)"}}></div>
				<div className={styles.name}>{version.name}</div>
			</div>
			<div>
				<div className={styles.date}>создана {version.dateOfCreation?.toLocaleDateString()}</div>
				<div className={styles.buttons}>
					<div>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.71667 5.51667L10.4833 6.28333L2.93333 13.8333H2.16667V13.0667L9.71667 5.51667ZM12.7167 0.5C12.5083 0.5 12.2917 0.583333 12.1333 0.741667L10.6083 2.26667L13.7333 5.39167L15.2583 3.86667C15.5833 3.54167 15.5833 3.01667 15.2583 2.69167L13.3083 0.741667C13.1417 0.575 12.9333 0.5 12.7167 0.5ZM9.71667 3.15833L0.5 12.375V15.5H3.625L12.8417 6.28333L9.71667 3.15833Z" fill="#525252"/>
						</svg>
					</div>
					<div>
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M10 7.5C9.33831 7.50437 8.70496 7.76916 8.23706 8.23706C7.76916 8.70496 7.50437 9.33831 7.5 10C7.5 11.3683 8.63167 12.5 10 12.5C11.3675 12.5 12.5 11.3683 12.5 10C12.5 8.6325 11.3675 7.5 10 7.5Z" fill="#525252"/>
							<path d="M9.99943 4.16699C3.63859 4.16699 1.72693 9.68116 1.70943 9.73699L1.62109 10.0003L1.70859 10.2637C1.72693 10.3195 3.63859 15.8337 9.99943 15.8337C16.3603 15.8337 18.2719 10.3195 18.2894 10.2637L18.3778 10.0003L18.2903 9.73699C18.2719 9.68116 16.3603 4.16699 9.99943 4.16699ZM9.99943 14.167C5.54026 14.167 3.81276 10.962 3.39443 10.0003C3.81443 9.03533 5.54276 5.83366 9.99943 5.83366C14.4586 5.83366 16.1861 9.03866 16.6044 10.0003C16.1844 10.9653 14.4561 14.167 9.99943 14.167Z" fill="#525252"/>
						</svg>
					</div>
					<div>
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M11.6654 18.3337H8.33203C7.66536 18.3337 6.99703 17.3978 6.43703 16.062L4.16536 18.3337L3.33203 17.5003V14.167L5.31786 12.1812C5.11536 11.1045 4.9987 10.0478 4.9987 9.16699C4.9987 5.83366 7.4987 1.66699 9.9987 1.66699C12.4987 1.66699 14.9987 5.83366 14.9987 9.16699C14.9987 10.0487 14.882 11.1045 14.6795 12.1812L16.6654 14.167V17.5003L15.832 18.3337L13.5604 16.062C13.0004 17.3978 12.3329 18.3337 11.6654 18.3337ZM9.9987 16.667H8.65286C8.59979 16.6003 8.55001 16.531 8.5037 16.4595C8.21453 16.0162 7.9037 15.322 7.6212 14.4737C7.04453 12.742 6.66536 10.6295 6.66536 9.16699C6.66536 6.50199 8.70203 3.33366 9.9987 3.33366C11.2954 3.33366 13.332 6.50199 13.332 9.16699C13.332 10.6295 12.9529 12.7428 12.3762 14.4737C12.0929 15.322 11.7829 16.0153 11.4929 16.4595C11.4468 16.531 11.3973 16.6003 11.3445 16.667H9.9987ZM9.9987 10.0003C10.4407 10.0003 10.8646 9.82473 11.1772 9.51217C11.4898 9.19961 11.6654 8.77569 11.6654 8.33366C11.6654 7.89163 11.4898 7.46771 11.1772 7.15515C10.8646 6.84259 10.4407 6.66699 9.9987 6.66699C9.55667 6.66699 9.13275 6.84259 8.82019 7.15515C8.50763 7.46771 8.33203 7.89163 8.33203 8.33366C8.33203 8.77569 8.50763 9.19961 8.82019 9.51217C9.13275 9.82473 9.55667 10.0003 9.9987 10.0003Z" fill="#525252"/>
						</svg>
					</div>
					<div>
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clipPath="url(#clip0_3366_20829)">
								<path d="M14 6.75V14.25H8V6.75H14ZM12.875 2.25H9.125L8.375 3H5.75V4.5H16.25V3H13.625L12.875 2.25ZM15.5 5.25H6.5V14.25C6.5 15.075 7.175 15.75 8 15.75H14C14.825 15.75 15.5 15.075 15.5 14.25V5.25Z" fill="#525252"/>
							</g>
							<defs>
								<clipPath id="clip0_3366_20829">
									<rect width="20" height="20" fill="white"/>
								</clipPath>
							</defs>
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
};

export { VersionItem };