import React from 'react';
import styles from './index.module.scss';
import {ILandingCard} from "../../../../models/landings/constructor/ILandingsDTO/ILandingCard";
import {VersionItem} from "./VersionItem";

interface IProps {
	landing?: ILandingCard
}

const LandingVersions:React.FC<IProps> = ({landing}) => {

	return (
		<div className={styles.main}>
			<div className={styles.title}>
				Версии страницы
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M9.03844 15.76H10.9584V13.84H9.03844V15.76ZM9.99844 0.400024C4.69924 0.400024 0.398438 4.70082 0.398438 10C0.398438 15.2992 4.69924 19.6 9.99844 19.6C15.2976 19.6 19.5984 15.2992 19.5984 10C19.5984 4.70082 15.2976 0.400024 9.99844 0.400024ZM9.99844 17.68C5.76484 17.68 2.31844 14.2336 2.31844 10C2.31844 5.76642 5.76484 2.32002 9.99844 2.32002C14.232 2.32002 17.6784 5.76642 17.6784 10C17.6784 14.2336 14.232 17.68 9.99844 17.68ZM9.99844 4.24002C7.87684 4.24002 6.15844 5.95842 6.15844 8.08002H8.07844C8.07844 7.02402 8.94244 6.16002 9.99844 6.16002C11.0544 6.16002 11.9184 7.02402 11.9184 8.08002C11.9184 10 9.03844 9.76003 9.03844 12.88H10.9584C10.9584 10.72 13.8384 10.48 13.8384 8.08002C13.8384 5.95842 12.12 4.24002 9.99844 4.24002Z" fill="#BABABA"/>
				</svg>
			</div>
			<div className={styles.list}>
				{landing?.versions?.map((ver) => <VersionItem version={ver}/>)}
			</div>
		</div>
	);
};

export { LandingVersions };