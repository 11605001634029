import styles from './index.module.scss';

const BannerWithQuestionMark = () => {
  return (
    <div className={ styles.main }>
      <div className={ styles.content }>
        <div className={ styles.word1 }>Возникли</div>

        <div className={ styles.word2 }>ВОПРОСЫ?</div>

        <div className={ styles.answers }>У нас есть ответы!</div>

        <div className={ styles.action }>
          <a href='https://sberfriend.sbrf.ru/sberfriend/#/interaction/new?elementId=11969'>ПОЛУЧИТЬ ОТВЕТЫ</a>
        </div>
      </div>

      <div className={ styles.image }>
        <img src='https://sbidea.ru/attachments/1/resized_for_show_fc/7ac7fe-d3ba-4aef-b4d4-7c827553cd80/question_mark.png' alt=''/>
      </div>
    </div>
  );
};

export { BannerWithQuestionMark };