import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGvkCampaign } from "./IGvkCampaign";
import { IDemoInformation } from "../../../models/surveys/Campaigns/IDemoInformation";
import { IStepDetailsDTO } from "./IStepDetailsDTO";
import { INewStepDTO } from "./IStepDTO";
import { IEntrieDTO } from './IEntrieDTO';
import { ISurveyDTO } from 'models/surveys/Survey/ISurveyDTO';
import { ILandingEntrieContent } from './ILandingContent';

const GVKSlice = createSlice({
	name: 'gvk',
	initialState: {
		participationId: '',
		sessionId: '',
		userId: '',
		stepId: '',
		newActualStep: {},
		actualStepDetails: {},
		campaignName: '',
		actualEntrie: {},
		actualEntrieTypeSurveyContent: {},
		actualEntrieTypelandingContent: {},
		actualEntries: [{}],
		demoParams: [],
		campaignState: '',
		actualParameters: [],
		campaignStateDescription: '',
		manualFillingParametersLink: null
	} as unknown as IGvkCampaign,
	reducers: {

		setActualNewStep(state, action: PayloadAction<INewStepDTO>) {
			state.newActualStep = action.payload;
		},

		setActualStepDetails(state, action: PayloadAction<IStepDetailsDTO>) {
			state.actualStepDetails = action.payload;
		},

		setActualEntrie(state, action: PayloadAction<IEntrieDTO>) {
			state.actualEntrie = action.payload;
		},

		setActualEntries(state, action: PayloadAction<Array<IEntrieDTO>>) {
			state.actualEntries = action.payload;
		},

		setActualEntrieTypeSurveyContent(state, action: PayloadAction<ISurveyDTO>) {
			state.actualEntrieTypeSurveyContent = action.payload
		},

		setActualEntrieTypeLandingContent(state, action: PayloadAction<ILandingEntrieContent>) {
			state.actualEntrieTypeLandingContent = action.payload
		},

		setParticipationId(state, action: PayloadAction<string>) {
			state.participationId = action.payload;
		},

		setSessionId(state, action: PayloadAction<string>) {
			state.sessionId = action.payload;
		},

		setUserId(state, action: PayloadAction<string>) {
			state.userId = action.payload;
		},

		setStepId(state, action: PayloadAction<string>) {
			state.stepId = action.payload;
		},

		saveCampaignStatusGVK(state, action: PayloadAction<IDemoInformation>) {
			state.demoParams = action.payload.actualParameters;
			state.campaignState = action.payload.campaignState;
			state.campaignStateDescription = action.payload.campaignStateDescription;
			state.manualFillingParametersLink = action.payload.manualFillingParametersLink || '';
			state.campaignName = 'campaign';
		},
	},
});

export const {
	setActualNewStep,
	setActualStepDetails,
	setActualEntrie,
	setActualEntrieTypeSurveyContent,
	setActualEntrieTypeLandingContent,
	setActualEntries,
	setParticipationId,
	setSessionId,
	setUserId,
	setStepId
} = GVKSlice.actions;
export default GVKSlice.reducer;
