import React, {useEffect} from 'react';
import styles from "./index.module.scss";
import {setNotificationsScreenOpen} from "../../../../../redux/slices/initiative";
import {useDispatch, useSelector} from "react-redux";
import {InitiativeNotificationsPanel} from "../index";
import {RootState} from "../../../../../redux/storeToolkit";
import {useGetNotificationsCountQuery} from "../../../../../redux/queries/Notifications/notifications.api";
import {useWindowSize} from "../../../../../hooks/useSize";

const NotificationIconWidget = () => {

	const dispatch = useDispatch();
	const open = useSelector((state: RootState) => state.initiative.isNotificationsScreenOpen);

	const isAuth = useSelector((state: RootState) => state.auth.role) === 'REGISTERED';

	const {data: CountData, isLoading: isLoadingCount, refetch} = useGetNotificationsCountQuery(undefined, {skip: !isAuth,
		pollingInterval: 60000,
	});

	const [pageW] = useWindowSize();

	const openNotificationsPanel = () => {
		dispatch(setNotificationsScreenOpen(true))
	}

	useEffect(() => {
		if (isAuth) {
			refetch();
		}
	},[])

	if (pageW < 1000) {
		return <></>
	}

	return (
		<>
			{/*Вот тут находится вся панель с уведомлениями*/}
			{open && <InitiativeNotificationsPanel/>}

			<div className={styles.main} onClick={openNotificationsPanel}>
				<button className={styles.icon}>
					<svg xmlns="http://www.w3.org/2000/svg" width="18.000000" height="21.500000" viewBox="0 0 18 21.5" fill="current">
						<path id="Vector" d="M7.00977 19.51C7.00977 20.6101 7.90039 21.5 9 21.5C10.0996 21.5 10.9902 20.6101 10.9902 19.51L7.00977 19.51ZM9 4.5C11.7598 4.5 14 6.73999 14 9.5L14 16.5L4 16.5L4 9.5C4 6.73999 6.24023 4.5 9 4.5ZM9 0C8.16992 0 7.5 0.669922 7.5 1.5L7.5 2.66992C4.36035 3.3501 2 6.1499 2 9.5L2 15.5L0 17.5L0 18.5L18 18.5L18 17.5L16 15.5L16 9.5C16 6.1499 13.6396 3.3501 10.5 2.66992L10.5 1.5C10.5 0.669922 9.83008 0 9 0Z" fill="#525252"/>
					</svg>
				</button>
				{
					CountData !== 0 && CountData && <div className={styles.count}>{CountData}</div>
				}
			</div>
		</>

	);
};

export { NotificationIconWidget };