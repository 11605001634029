import styles from './style.module.scss';
import cn from 'classnames';

type btnType = 'primary' | 'secondary' | 'no-fill';

type colorSchemeType = 'regular' | 'error' | 'black';

interface Props {
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  children?: React.ReactNode;
  type?: btnType;
  colorScheme?: colorSchemeType;
  disabled?: boolean;
  small?: boolean;
  className?: string;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  buttonTypeAttribute?: 'button' | 'submit' | 'reset' | undefined;
  id?: string
}

const Button = ({
  id,
  onClick,
  type = 'primary',
  colorScheme = 'regular',
  disabled = false,
  small = false,
  children,
  className = '',
  iconStart,
  iconEnd,
  buttonTypeAttribute = 'button',
}: Props) => {
  return (
    <button
      id={id}
      className={cn([styles.btn, styles[`btn-${type}`], styles[`btn-${type}_${colorScheme}`], className, small && styles[`btn-${type}_small`]])}
      onClick={onClick}
      disabled={disabled}
      type={buttonTypeAttribute}
    >
      {iconStart ? <div className={cn([styles.btn__iconContainer, styles['btn__icon-container_start']])}>{iconStart}</div> : ''}
      {children ? children : ''}
      {iconEnd ? <div className={cn([styles.btn__iconContainer, styles['btn__icon-container_end']])}>{iconEnd}</div> : ''}
    </button>
  );
};

export { Button }
