import styles from './index.module.scss';
import cn from "classnames";

interface IProps {
  text?: string
  className?: string
}

const Preloader = ({ text, className } : IProps) => {
  return (
    <div className={ cn([styles.main, className])}>
      <div className={ styles.icon }>
        <img src="/img/loader.png" alt=""/>
      </div>

      <div className={ styles['text-container'] }>
        { text || 'Немного подождите' }
      </div>
    </div>
  )
};

export { Preloader };