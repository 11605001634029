import React, { useState, useEffect, useRef } from 'react';
import { StoppedPropagination } from '../StoppedPropagination';
import { TimePicker, DatePicker } from '..';
import styles from './index.module.scss';
import cn from 'classnames';
import ReactDOM from 'react-dom';

export interface IDateRestrictions {
  noPrev?: boolean;
  noNext?: boolean;
  noMoreThan?: Date;
  noLessThan?: Date;
}

interface Props {
  value: Date | undefined;
  onChange: (value: Date | undefined) => void;
  className?: string;
  hasTimePicker?: boolean;
  hasDatePicker?: boolean;
  position?: 'bottom' | 'top';
  disabled?: boolean;
  placeholder?: string;
  widthFull?: boolean;
  tooltip?: string;
  error?: string;
  restrictions?: IDateRestrictions;
}

interface IBackGround {
  portalPlace?: HTMLDivElement | null;
  visible?: boolean;
  onClick?: ((event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
}

export const Background = ({
  // добавил компонент прозрачного задника, для удобного использования
  onClick,
  visible,
  portalPlace,
}: IBackGround) => {
  return ReactDOM.createPortal(
    <div onClick={onClick} className={`pop-up-background ${visible ? 'pop-up-background_visible' : ''}`}></div>,
    portalPlace ? portalPlace : document.body
  );
};

export const DateTimePicker = ({
  value,
  onChange,
  className,
  hasTimePicker = true,
  hasDatePicker = true,
  position = 'bottom',
  disabled = false,
  placeholder = 'Дата',
  widthFull = false,
  error,
  tooltip,
  restrictions,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = () => setIsOpen((prev) => !prev);

  const resetValue = () => {
    onChange(undefined);
  };

  const pickerContainer = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //
  //   if (isOpen) {
  //     const listener = () => {
  //       setIsOpen(false);
  //     };
  //     document.addEventListener("click", listener);
  //     return () => {
  //       document.removeEventListener("click", listener);
  //     };
  //   }
  // }, [isOpen]);

  useEffect(() => {
    if (value && restrictions?.noMoreThan && value > restrictions.noMoreThan) {
      onChange(undefined);
    }
    if (value && restrictions?.noLessThan && value < restrictions.noLessThan) {
      onChange(undefined);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!pickerContainer.current?.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };
    const handleESC = (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };
    const handleScroll = () => {
      setIsOpen(false);
    };

    pickerContainer.current?.addEventListener('keydown', handleESC);
    document.addEventListener('scroll', handleScroll);
    document.addEventListener('click', handleClick);

    return () => {
      pickerContainer.current?.removeEventListener('keydown', handleESC);
      document.removeEventListener('click', handleClick);
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={cn(styles.main, className, isOpen && styles.open, widthFull && styles.main_width_full)} ref={pickerContainer}>
        {hasDatePicker && (
          <>
            <button className={cn(styles['input-field'], isOpen && styles['input-field-active'])} onClick={toggleOpen} disabled={disabled}>
              <div className={styles['local-date-string']}>
                {hasDatePicker && value && value.toLocaleDateString()}
                {hasDatePicker && hasTimePicker && value && ' в '}
                {hasTimePicker &&
                  value &&
                  (value.getHours() > 9 ? value.getHours() : '0' + value.getHours()) +
                    ':' +
                    (value.getMinutes() > 9 ? value.getMinutes() : '0' + value.getMinutes())}
                {!value && placeholder}
              </div>
              {value ? (
                <svg
                  onClick={(e) => {
                    resetValue();
                    e.stopPropagation();
                  }}
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={styles['close-icon']}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
                    fill="#757575"
                  />
                </svg>
              ) : (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={styles['calendar-icon']}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.25 2.25H13.5V0.75H12V2.25H6V0.75H4.5V2.25H3.75C2.9175 2.25 2.2575 2.925 2.2575 3.75L2.25 14.25C2.25 15.075 2.9175 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM14.25 14.25H3.75V6H14.25V14.25ZM5.25 7.5H9V11.25H5.25V7.5Z"
                    fill="#757575"
                  />
                </svg>
              )}
            </button>
            {(tooltip || error) && (
              <div className={styles.tooltip}>
                {tooltip}
                <span className={styles.error}> {error}</span>
              </div>
            )}
            <StoppedPropagination>
              <div className={cn(styles.container, styles[`container-${position}`], !isOpen && styles['container-closed'])}>
                {hasDatePicker && <DatePicker value={value} visible={isOpen} onChange={onChange} restrictions={restrictions} />}
                {hasTimePicker && <TimePicker value={value} onChange={onChange} visible={isOpen} />}
              </div>
            </StoppedPropagination>
            <Background onClick={() => setIsOpen(false)} visible={isOpen} portalPlace={pickerContainer?.current} />
          </>
        )}
      </div>
    </>
  );
};
