import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { LabelRequiredHOC } from '../LabelRequiredHOC';
import { WithErrorHint } from '../WithErrorHint';
import styles from './style.module.scss';

const TextFieldWithState = (props: any) => {
  const [val, setVal] = useState(props.value);

  // ошибка обязательности заполнения поля
  const reqError = props.required && (!val || val.toString().length === 0)

  return (
    <WithErrorHint errorHint={reqError && 'Обязательное поле'}>
      <TextField
        {...props}
        value={val}
        error={props.error || reqError} // ошибка придёт или сверху, или нарушена обязательность поля
        onChange={(e: any) => {
          setVal(e.target.value);
          props.updateState && props.updateState(e.value);
        }}
      />
    </WithErrorHint>
  );
};
const TextField = ({ className, ...props }: any) => {
  const textarea = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textarea.current) {
      const elem: HTMLTextAreaElement = textarea.current;
      elem.style.cssText = 'height:0px';
      elem.style.cssText = `height:${elem.scrollHeight}px`;
    }
  }, [props.value]);

  props = { ...props, error: '' }; // потом разобраться зачем это
  return (
    <>
      <div className={cn(styles.idea_text_field, { error: props.error }, className)}>
        {!props.many && (
          <input
            {...props}
            autoFocus={props.autofocus}
            value={props.value === null ? '' : props.value}
            onChange={props.onChange}
            onBlur={(e: any) => (props.onBlur ? props.onBlur(e.target.value) : '')}
            className={styles['idea-input']}
            placeholder={props.placeholder}
          />
        )}
        {props.many && (
          <textarea
            {...props}
            ref={textarea}
            value={props.value}
            onChange={props.onChange}
            onBlur={(e: any) => (props.onBlur ? props.onBlur(e.target.value) : '')}
            className={styles['idea-input']}
            placeholder={props.placeholder}
          />
        )}
        <span className={styles.start_icon}>{props.start_icon}</span>
        <span className={styles.end_icon}>{props.end_icon}</span>
      </div>
      {props.tooltip && <div className={cn(styles.idea_text_field_tooltip, { error: props.error })}>{props.tooltip}</div>}
    </>
  );
};

const TextFieldWithLabel = LabelRequiredHOC(TextField);
const TextFieldWithStateWithLabel = LabelRequiredHOC(TextFieldWithState);

export { TextField, TextFieldWithLabel, TextFieldWithState, TextFieldWithStateWithLabel };
