import React, { useRef, useEffect } from 'react';

interface Props {
  value: Date | undefined;
  onChange: (param: Date | undefined) => void;
  visible?: boolean;
}

const HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

const MINUTES = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

export const TimePicker = ({ value, visible = false, onChange }: Props) => {
  const setCurrenHour = (hour: number) => {
    const date = value ? new Date(value) : new Date();
    date.setHours(hour);
    onChange(date);
  };
  const setCurrentMinute = (minute: number) => {
    const date = value ? new Date(value) : new Date();
    date.setMinutes(minute);
    onChange(date);
  };

  const hoursRef = useRef<HTMLDivElement | null>(null);
  const minutesRef = useRef<HTMLDivElement | null>(null);
  useRef(null);
  useEffect(() => {
    //let mounted = true;
    if (hoursRef?.current) {
      hoursRef.current.scrollIntoView();
    }
    if (minutesRef?.current) {
      minutesRef.current.scrollIntoView();
    }
  }, [visible]);
  // useEffect(() => {
  //   let mounted = true;
  //   // if (hoursRef?.current) {
  //   //   hoursRef.current.scrollIntoView({
  //   //     behavior: "smooth",
  //   //   })
  //   // }
  //   if (minutesRef?.current) {
  //     minutesRef.current.scrollIntoView({
  //       behavior: "smooth",
  //     });
  //   }
  // }, [visible]);
  return (
    <div className={`time-picker ${visible ? 'time-picker_open' : ''}`}>
      <div className="time-picker-header">Время</div>
      <div className="time-picker-containers">
        {' '}
        <div className="time-picker-hours-container">
          {HOURS.map((hour, index) => {
            return (
              <div
                className={`time-picker-hour ${new Date().getHours() === hour ? 'time-picker-hour_current' : ''} ${
                  value && value.getHours() === hour ? 'time-picker-hour_active' : ''
                }`}
                key={index}
                onClick={() => {
                  setCurrenHour(hour);
                }}
                ref={value && value.getHours() === hour ? hoursRef : undefined}
              >
                {hour > 9 ? hour : '0' + hour}
              </div>
            );
          })}
        </div>
        <div className="time-picker-minutes-container">
          {MINUTES.map((minute, index) => {
            return (
              <div
                className={`time-picker-minute ${new Date().getMinutes() === minute ? 'time-picker-minute_current' : ''} ${
                  value && value.getMinutes() === minute ? 'time-picker-minute_active' : ''
                }`}
                key={index}
                onClick={() => {
                  setCurrentMinute(minute);
                }}
                ref={value && value.getMinutes() === minute ? minutesRef : undefined}
              >
                {minute > 9 ? minute : '0' + minute}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
