import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './redux/storeToolkit';
import { Provider } from 'react-redux';
// список полифилов для IE
import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';
import 'core-js/es/promise';
import { BrowserRouter as Router } from 'react-router-dom';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';
import { sanitize } from 'dompurify';

// конфигурация всплывающих подсказок
const options = {
  position: positions.TOP_RIGHT,
  timeout: 3000,
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

const AlertTemplate = ({ style, options, message, close }: any) => {
  return (
    <div
      style={style}
      className={`sberidea-alert${options.type === 'info' ? ' sberidea-alert-info' : ''}${
        options.type === 'success' ? ' sberidea-alert-success' : ''
      }${options.type === 'error' ? ' sberidea-alert-error' : ''}`}
    >
      <div className="alert-icon">
        {options.type === 'info' && (
          <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5zm.75 11.25h-1.5v-4.5h1.5v4.5zm0-6h-1.5v-1.5h1.5v1.5z"
              fill="#5099F6"
            />
          </svg>
        )}
        {options.type === 'success' && (
          <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5zM9 15c-3.308 0-6-2.693-6-6 0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6 0 3.307-2.693 6-6 6zm3.443-9.315L7.5 10.627 5.558 8.694 4.5 9.75l3 3 6-6-1.057-1.065z"
              fill="#219428"
            />
          </svg>
        )}
        {options.type === 'error' && (
          <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 4.492l5.648 9.758H3.352L9 4.492zM9 1.5L.75 15.75h16.5L9 1.5zM9.75 12h-1.5v1.5h1.5V12zm0-4.5h-1.5v3h1.5v-3z"
              fill="#ED6A5A"
            />
          </svg>
        )}
      </div>
      <div className="alert-message" dangerouslySetInnerHTML={{__html: sanitize(message)}}/>
      <div className="alert-close">
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={close}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.25 4.808L13.193 3.75 9 7.942 4.808 3.75 3.75 4.808 7.942 9 3.75 13.193l1.058 1.057L9 10.057l4.193 4.193 1.057-1.057L10.057 9l4.193-4.192z"
          />
        </svg>
      </div>
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <AlertProvider {...options} template={AlertTemplate}>
          <App />
        </AlertProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
