import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { index } from './authorization';
import { RootState, store } from '../redux/storeToolkit';
import { logIn, logOut } from 'redux/slices/authSlice';
import jwt_decode from 'jwt-decode';
import { IUserInfo } from '../redux/constants';
import queryString from 'query-string';

const readToken = () => {
  // читаем сохранённый токен
  // читаем токен из редакса
  const tokenFromStore: string = (store.getState() as RootState).auth.access_token || '';
  return tokenFromStore;
};

const config: AxiosRequestConfig = {
  baseURL: process.env.NODE_ENV === 'development' ? 'https://tkldw-scs000001.delta.sbrf.ru:10443/api' : '/api',
  timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params: Record<string, unknown>) => queryString.stringify(params, {arrayFormat: 'none'})
};

export const instance: AxiosInstance = axios.create(config);

// интерцептор при запросе
// подставляет в заголовок токен доступа

instance.interceptors.request.use(
  (config) => {
    const token: string = readToken();
    if (config.url !== '/authorization/refresh' && token !== '') config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // Do something with response error
    const originalRequest = error.config;
    // токен истёк, необходимо его обновить

    if (error?.response?.status === 401 && (originalRequest?.url === '/authorization/refresh')) {
      return Promise.reject(error);
    }

    if (error?.response?.status === 401 && (originalRequest?.url === '/authorization/auth-guest')) {
      return Promise.reject({
        ...error,
        response: {
          ...error.response,
          status: 501
        }
      });
    }

    if (error?.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;
      //return instance.post('/authorization/refresh')
      return index
        .refreshToken()
        .then((res) => {
          // обновим инфу в сторе
          const jwtDecoded: any = jwt_decode(res.accessToken || '');
          const newUserInfo: IUserInfo = {
            name: jwtDecoded?.user_name || 'Гость',
            role: jwtDecoded?.user_type || 'Guest',
            access_token: res.accessToken,
          };
          store.dispatch(logIn(newUserInfo));
          // вызываем предыдущий запрос
          return instance(originalRequest);
        })
        .catch((e) => {
          if (e?.response?.status === 401) {
            // неудачная попытка обновить токен
            // разлогиниваемся и стираем токен
            index.logout();
            store.dispatch(logOut());
            //window.location.reload()
            // и снова логинимся, но уже гостем
            return index
              .getGuestToken()
              .then((res) => {
                const jwtDecoded: any = jwt_decode(res.accessToken || '');
                const newUserInfo: IUserInfo = {
                  name: jwtDecoded?.user_name || 'Гость',
                  role: jwtDecoded?.user_type || 'Guest',
                  access_token: res.accessToken,
                };
                store.dispatch(logIn(newUserInfo));
                return instance(originalRequest);
              })
              .catch(
                // выкинем "наверх"
                (e) => {
                  console.log({error})
                  throw error;
                }
              );
          }
          //throw error
        });
    }

    return Promise.reject(error);
  }
);
