import React from 'react';
import styles from './index.module.scss';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';

interface IProps {
  serviceName: string;
}

const ServiceUnavailable = ({ serviceName }: IProps) => {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <ReactSVG src="/img/statusError/catAndKeys.svg" />
        <div className={styles.title}>{`Сервис ${serviceName} недоступен`}</div>
        <div className={styles.descr}>Портал на обслуживании и временно не работает</div>
        <Link to="/">Вернуться на главную</Link>
      </div>
    </div>
  );
};

export { ServiceUnavailable };
