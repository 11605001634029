import React from 'react';

const Access = () => {
	return (
		<div>
			Доступ
		</div>
	);
};

export {Access};