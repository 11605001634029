import { Navigate, Outlet } from 'react-router-dom';
import { RootState, store } from '../../redux/storeToolkit';
import React from 'react';

interface IProps {
  moduleName: string
  alwaysAvailable?: boolean
}

const AvailableRoute = ({ moduleName, alwaysAvailable }: IProps) => {
  const moduleIsAvailable: boolean =
    alwaysAvailable ||
    (store.getState() as RootState).availableModules.filter((module) => module.id === moduleName && module.status === 'ACTIVE').length > 0;

  return moduleIsAvailable ? <Outlet /> : <Navigate to="/not-available" />;
};

export { AvailableRoute };
