import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { AvailableRoute } from '../../AvailableRoute';

const Surveys = React.lazy(() => import('../Surveys').then(({ Surveys }) => ({ default: Surveys })));

const SurveyEditor = React.lazy(() => import('../SurveyEditor').then(({ SurveyEditor }) => ({ default: SurveyEditor })));
const SurveyManagementContainer = React.lazy(() => import('../SurveyManagementContainer').then(({SurveyManagementContainer}) => ({default: SurveyManagementContainer})));

const SurveyConstructorRoutes = (
  <Route path="survey-constructor" element={<AvailableRoute moduleName="surveysConstructor" />}>
    <Route index element={<Navigate to="/survey-constructor/surveys" replace />} />
    <Route path={`surveys`}>
      <Route index element={<Surveys />} />
      <Route path=':id'>
        <Route index element={<SurveyManagementContainer/>}/>
        <Route path='edit' element={<SurveyEditor/>} />
      </Route>
    </Route>
    <Route path="*" element={<Navigate to="/survey-constructor/surveys" replace />} />
  </Route>
);

export { SurveyConstructorRoutes };
