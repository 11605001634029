import {commonApi} from "../common.api";
import {Notifications, NotificationsCategories} from "../../../models/notifications/INotificationsDTO";

export const notificationsApi = commonApi.injectEndpoints({
	endpoints: build => ({

		getNotifications: build.query<Notifications, { category: NotificationsCategories}>({
			query: ({category}) => ({
				url: `/discussion-instance-query/notification-message/v1.0/${category}`,
			}),
			providesTags: ['Notifications']
		}),

		getNotificationsCount: build.query<number, void>({
			query: () => ({
				url: `/discussion-instance-query/notification-message/v1.0/total-count`,
			}),
			providesTags: ['NotificationsCount']
		}),

		readNotificationById: build.mutation<void, string>({
			query: (id) => ({
				url: `/discussion-instance-query/notification-message-command/v1.0/${id}`,
				method: "PUT"
			}),
			invalidatesTags: ['Notifications', 'NotificationsCount']
		}),

		readNotificationsByCategory: build.mutation<void, NotificationsCategories>({
			query: (category) => ({
				url: `/discussion-instance-query/notification-message-command/v1.0/category/${category}`,
				method: "PUT"
			}),
			invalidatesTags: ['Notifications', 'NotificationsCount']
		})
	})
})

export const {
	useGetNotificationsCountQuery,
	useGetNotificationsQuery,
	useReadNotificationByIdMutation,
	useReadNotificationsByCategoryMutation,
} = notificationsApi