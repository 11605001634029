import {instance} from "../../index";

// это типы доступных entry из БД
export type WorkingDirectoryEntryType = "PAGE_DRAFT" | "PAGE_TEMPLATE" | "PAGE_ITEM_TEMPLATE"

const WorkingDirectoryEntryTypes = {
	PAGE_DRAFT: "bbeaa4be-8fdd-47dd-bc12-2cf3abb9b95d",
	PAGE_TEMPLATE: "5c050432-365e-4251-a88c-0a5df012b86b",
	PAGE_ITEM_TEMPLATE: "4dcb4ee6-ccfb-41d4-808e-fd795ba14376"
}

export const pageConstructorApi = {

	getWorkingDirectoryEntryList(pageId: string) {
		return instance.get(`/page-constructor/directory/${pageId}/entries`)
	},

	getWorkingDirectoryEntryTypes() {
		return instance.get(`/page-constructor/entry-types`)
			.then((response) => response.data)
			.catch((error) => {throw error;});
	},

	createWorkingDirectoryEntry(pageId: string, EntryType: WorkingDirectoryEntryType) {
		const body = {

			// пока не знаю кто еще может быть автором Entry
			authorType: "SYSTEM",

			// Сделал локальный справочник для удобства
			workingDirectoryEntryType: WorkingDirectoryEntryTypes[EntryType]
		}
		return instance.post(`/page-constructor/directory/${pageId}/entries`, body)
			.then((response) => response.data)
			.catch((error) => {throw error;});
	},

	deleteWorkingDirectoryEntry(pageId: string, EntryId: string) {
		return instance.delete(`/page-constructor/directory/${pageId}/entries/${EntryId}`);
	},

	getPageItemTypes() {
		return instance.get(`/page-constructor/page-item-types`)
			.then((response) => response.data)
			.catch((error) => {throw error;});
	},

	getPageItemsList(entryId: string) {
		return instance.get(`/page-constructor/entries/${entryId}/page-items`)
			.then((response) => response.data)
			.catch((error) => {throw error;});
	},

	createPageItem(entryId: string, itemTypeId: string, parent_id: string | undefined) {
		const body = {
			parent_id,
			page_item_type_id: itemTypeId
		}

		return instance.post(`/page-constructor/entries/${entryId}/page-items`, body)
			.then((response) => response.data)
			.catch((error) => {throw error;});
	}

}