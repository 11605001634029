import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';
import cn from 'classnames';

interface Props {
  children?: React.ReactNode;
  onClick?: ((event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  visible?: boolean;
  withBlur?: boolean;
  className?: string;
  samePlace?: boolean;
  unmountOnClose?: boolean;
}

export const PopUpBackground = ({
  unmountOnClose = true,
  children,
  onClick,
  visible = false,
  withBlur = false,
  className,
  samePlace = false,
}: Props) => {
  useEffect(() => {
    const onEscClick = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClick) {
        onClick();
      }
    };
    document.addEventListener('keydown', onEscClick);
    return () => {
      document.removeEventListener('keydown', onEscClick);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (visible) {
      //document.body.style.position = 'fixed'
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
    } else {
      //document.body.style.position = ''
      document.body.style.height = '';
      document.body.style.overflow = '';
    }
  }, [visible]);

  if (samePlace) {
    return (
      <div
        onClick={onClick}
        className={cn([styles['pop-up-background'], visible && styles['pop-up-background_visible'], withBlur && styles['pop-up-background_with-blur'], className])}
      >
        {(visible || !unmountOnClose) && children}
      </div>
    );
  }
  return ReactDOM.createPortal(
    <div
      // onClick={onClick}
      onMouseDown={onClick}
      className={cn(styles['pop-up-background'], visible && styles['pop-up-background_visible'], withBlur && styles['pop-up-background_with-blur'], className)}
    >
      {(visible || !unmountOnClose) && children}
    </div>,
    document.body
  );
};
