import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  useEffect, useRef,
  useState
} from 'react';
import styles from './index.module.scss';

interface IProps {
  children: ReactNode
  pageWidth: number
}

const Carousel = ({ children, pageWidth } : IProps) => {
  const [pages, setPages] = useState<any>([]);
  const [offset, setOffset] = useState(0);
  const refWindow = useRef<any>(null);

  let windowWidth = 0;
  if (refWindow?.current) windowWidth = refWindow.current?.getBoundingClientRect()?.width;
  const enableArrowLeft = offset < 0;
  const maxOffset = -(pageWidth * (pages.length));
  const enableArrowRight = Math.abs(maxOffset-offset) >= windowWidth;

  useEffect(
    () => {
      setPages(
        Children.map<ReactNode, ReactNode>(
          children, (child) => {
            if (isValidElement(child)) {
              const elementStyle: React.CSSProperties = {
                height: '100%',
                minWidth: `${pageWidth}px`,
                maxWidth: `${pageWidth}px`
              };
              return cloneElement(child, elementStyle)
            }
          }
        )
      )
    }
  ,[])

  const handleLeftArrowClick = () => {
    if (!enableArrowLeft) return;
    setOffset(currentOffset => Math.min(currentOffset + pageWidth,0))
  }

  const handleRightArrowClick = () => {

    if (!enableArrowRight) return;

    setOffset(currentOffset => {
      const newOffset = currentOffset - pageWidth;
      return Math.max(newOffset, maxOffset);
    })
  }

  return (
    <div className={ styles.main }>
      {
        (enableArrowLeft || enableArrowRight) &&
          <div className={ styles.buttons }>
            <button onClick={ handleLeftArrowClick } disabled={!enableArrowLeft}>&#8592;</button>
            <button onClick={ handleRightArrowClick } disabled={!enableArrowRight}>&#8594;</button>
          </div>
      }

      <div className={ styles.window } ref={refWindow}>
        <div
          className={ styles.all_pages_container }
          style={{
            transform: `translateX(${offset}px)`
          }}
        >
          { children }
        </div>
      </div>
    </div>
  );
};

export { Carousel };