import React from 'react';

interface Props {
  className?: string;
}

export const GoToUpButton = ({ className }: Props) => {
  return (
    <button
      className={`btn-go-to-up ${className ? className : ''}`}
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}
    >
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path fill="" d="M3 9L4.0575 10.0575L8.25 5.8725V15H9.75V5.8725L13.935 10.065L15 9L9 3L3 9Z" />
      </svg>
    </button>
  );
};
