import styles from './style.module.scss';
import { Link } from 'react-router-dom';

const StatusSomeError = () => {
  return (
    <div className={ styles.main }>
      <div className={ styles.container }>
        <div className={ styles.status }>
          <div className={ styles.code }>
            <svg width="805" height="191" viewBox="0 0 805 191" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 2.90997H110.819V24.867H24.3325V82.5374H100.239V103.965H24.3325V166.133H110.819V188.09H0V2.90997Z"
                fill="#525252"
              />
              <path
                d="M213.49 116.928C211.727 117.104 209.964 117.193 208.2 117.193C206.614 117.193 204.85 117.193 202.911 117.193H176.462V188.09H152.13V4.23268C156.891 3.87996 162.004 3.43906 167.47 2.90997C172.054 2.55725 177.344 2.20452 183.339 1.85179C189.334 1.49907 195.593 1.32271 202.117 1.32271C214.46 1.32271 224.951 2.46907 233.591 4.76177C242.231 7.05448 249.195 10.5817 254.485 15.3435C259.951 20.1053 263.83 26.1016 266.122 33.3324C268.591 40.5632 269.825 49.0286 269.825 58.7285C269.825 72.4848 267.18 83.8601 261.891 92.8546C256.777 101.673 248.578 108.11 237.294 112.166L281.198 188.09H253.692L213.49 116.928ZM244.435 59.5221C244.435 53.1731 243.729 47.7941 242.319 43.385C240.908 38.976 238.616 35.3606 235.442 32.5388C232.268 29.717 228.037 27.6888 222.747 26.4543C217.457 25.0434 210.845 24.3379 202.911 24.3379C199.384 24.3379 196.034 24.4261 192.86 24.6025C189.687 24.6025 186.865 24.6907 184.397 24.867C181.399 25.0434 178.666 25.1316 176.198 25.1316V94.9709H202.911C217.722 94.9709 228.301 92.0609 234.649 86.241C241.173 80.2447 244.435 71.3384 244.435 59.5221Z"
                fill="#525252"
              />
              <path
                d="M382.667 116.928C380.904 117.104 379.14 117.193 377.377 117.193C375.79 117.193 374.027 117.193 372.087 117.193H345.639V188.09H321.307V4.23268C326.067 3.87996 331.181 3.43906 336.647 2.90997C341.231 2.55725 346.521 2.20452 352.516 1.85179C358.511 1.49907 364.77 1.32271 371.294 1.32271C383.637 1.32271 394.128 2.46907 402.768 4.76177C411.407 7.05448 418.372 10.5817 423.662 15.3435C429.128 20.1053 433.007 26.1016 435.299 33.3324C437.768 40.5632 439.002 49.0286 439.002 58.7285C439.002 72.4848 436.357 83.8601 431.067 92.8546C425.954 101.673 417.755 108.11 406.47 112.166L450.375 188.09H422.868L382.667 116.928ZM413.611 59.5221C413.611 53.1731 412.906 47.7941 411.496 43.385C410.085 38.976 407.793 35.3606 404.619 32.5388C401.445 29.717 397.213 27.6888 391.924 26.4543C386.634 25.0434 380.022 24.3379 372.087 24.3379C368.561 24.3379 365.211 24.4261 362.037 24.6025C358.863 24.6025 356.042 24.6907 353.574 24.867C350.576 25.0434 347.843 25.1316 345.375 25.1316V94.9709H372.087C386.899 94.9709 397.478 92.0609 403.826 86.241C410.349 80.2447 413.611 71.3384 413.611 59.5221Z"
                fill="#525252"
              />
              <path
                d="M555.338 191C542.29 191 530.741 189.148 520.69 185.445C510.816 181.741 502.441 176.009 495.564 168.249C488.688 160.489 483.486 150.613 479.96 138.62C476.433 126.628 474.67 112.254 474.67 95.5C474.67 78.7456 476.433 64.3721 479.96 52.3795C483.486 40.3869 488.688 30.5106 495.564 22.7507C502.441 14.9908 510.816 9.259 520.69 5.55539C530.741 1.8518 542.29 0 555.338 0C568.738 0 580.464 1.76362 590.514 5.29085C600.564 8.8181 608.94 14.4617 615.64 22.2216C622.516 29.8052 627.63 39.6814 630.98 51.8504C634.33 63.843 636.005 78.3929 636.005 95.5C636.005 112.254 634.242 126.628 630.716 138.62C627.189 150.613 621.987 160.489 615.111 168.249C608.234 176.009 599.771 181.741 589.721 185.445C579.67 189.148 568.209 191 555.338 191ZM555.338 168.778C564.33 168.778 572.265 167.544 579.141 165.075C586.018 162.429 591.748 158.197 596.333 152.377C601.093 146.557 604.62 138.973 606.912 129.626C609.38 120.279 610.615 108.903 610.615 95.5C610.615 82.0965 609.38 70.7211 606.912 61.374C604.62 52.0268 601.093 44.4432 596.333 38.6233C591.748 32.8033 586.018 28.6588 579.141 26.1897C572.265 23.5443 564.33 22.2216 555.338 22.2216C546.521 22.2216 538.675 23.5443 531.799 26.1897C524.922 28.8352 519.103 33.0679 514.343 38.8878C509.758 44.7078 506.232 52.2913 503.763 61.6385C501.295 70.8093 500.06 82.0965 500.06 95.5C500.06 108.727 501.295 120.014 503.763 129.361C506.232 138.532 509.758 146.116 514.343 152.112C519.103 157.932 524.922 162.165 531.799 164.81C538.675 167.456 546.521 168.778 555.338 168.778Z"
                fill="#525252"
              />
              <path
                d="M737.292 116.928C735.529 117.104 733.766 117.193 732.002 117.193C730.416 117.193 728.652 117.193 726.713 117.193H700.264V188.09H675.932V4.23268C680.693 3.87996 685.806 3.43906 691.272 2.90997C695.856 2.55725 701.146 2.20452 707.141 1.85179C713.136 1.49907 719.395 1.32271 725.919 1.32271C738.262 1.32271 748.753 2.46907 757.393 4.76177C766.033 7.05448 772.997 10.5817 778.287 15.3435C783.753 20.1053 787.632 26.1016 789.924 33.3324C792.393 40.5632 793.627 49.0286 793.627 58.7285C793.627 72.4848 790.982 83.8601 785.693 92.8546C780.579 101.673 772.38 108.11 761.096 112.166L805 188.09H777.494L737.292 116.928ZM768.237 59.5221C768.237 53.1731 767.531 47.7941 766.121 43.385C764.71 38.976 762.418 35.3606 759.244 32.5388C756.07 29.717 751.839 27.6888 746.549 26.4543C741.259 25.0434 734.647 24.3379 726.713 24.3379C723.186 24.3379 719.836 24.4261 716.662 24.6025C713.489 24.6025 710.667 24.6907 708.199 24.867C705.201 25.0434 702.468 25.1316 700 25.1316V94.9709H726.713C741.524 94.9709 752.103 92.0609 758.451 86.241C764.975 80.2447 768.237 71.3384 768.237 59.5221Z"
                fill="#525252"
              />
            </svg>
          </div>
          <div className={ styles.cat }>
            <img src="/img/status500cat.svg" alt="" />
          </div>
        </div>
        <div className={ styles.title }>Что-то пошло не так</div>
        <div className={ styles.description }>
          Мы уже получили отчёт об этой ошибке и скоро всё починим. Попробуйте зайти позднее. Спасибо!
          <br />
          Вы можете{' '}
          <Link to="/" className={ styles.link }>
            вернуться на главную
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StatusSomeError;
