import React, {useEffect, useState} from 'react';
import styles from './index.module.scss'
import {SettingsHeader} from "./SettingsHeader";
import {ILandingCard} from "../../../models/landings/constructor/ILandingsDTO/ILandingCard";
import {landingsConstructorApi} from "../../../api/landingsConstructorApi";
import {useAlert} from "react-alert";
import {LandingBrowserPreview} from "./LandingBrowserPreview";
import {LandingVersions} from "./LandingVersions";
import {SettingsModal} from "./SettingsModal";
import {pageConstructorApi, WorkingDirectoryEntryType} from "../../../api/landingsConstructorApi/PageConstructor";
import {log} from "util";

const LandingSettings = () => {
	const alert = useAlert();
	const [landing, setLanding] = useState<ILandingCard | undefined>(undefined);
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	// функция получения списка Entry
	const getEntryList = async (pageId: string) => {
		try {
			const data = await pageConstructorApi.getWorkingDirectoryEntryList(pageId);
			console.log(data);
		} catch (e) {
			alert.error(e.message || 'Не получилось получить список Entry');
		}
	}

	const getEntryTypes = async () => {
		try {
			const data = await pageConstructorApi.getWorkingDirectoryEntryTypes();
			console.log(data);
		} catch (e) {
			alert.error(e.message || 'Не получилось получить типы Entry');
		}
	}

	const getPageItemTypes = async () => {
		try {
			const data = await pageConstructorApi.getPageItemTypes();
			console.log(data);
		} catch (e) {
			alert.error(e.message || 'Не получилось получить типы PageItem');
		}
	}

	// функция Удаления Entry
	const delEntry = async (pageId: string, entryId: string) => {
		try {
			await pageConstructorApi.deleteWorkingDirectoryEntry(pageId, entryId);
		} catch (e) {
			alert.error(e.message || 'Не получилось удалить Entry');
		}
	}

	// функция создания Entry
	const createEntry = async (pageId: string, entryType: WorkingDirectoryEntryType) => {
		try {
			await pageConstructorApi.createWorkingDirectoryEntry(pageId, entryType);
		} catch (e) {
			alert.error(e.message || `Не удалось создать Entry типа: ${entryType}`);
		}
	}

	useEffect(() => {
		const getLanding = async () => {
			try {
				let response = await landingsConstructorApi.getLanding('сюда id из ссылки');
				setLanding(response.data);
			} catch (e) {
				alert.error('Ошибка получения лендингов');
			}
		}
		getLanding();
	}, [])

	return (
		<>
			<div className={styles.main}>
				<SettingsModal onClose={() => setModalOpen(false)} visible={modalOpen} landing={landing}/>
				<SettingsHeader openSettings={()=> setModalOpen(true)} landing={landing}/>
				<LandingBrowserPreview landing={landing}/>
				<LandingVersions landing={landing}/>
			</div>
		</>
	);
};

export { LandingSettings };