import { configureStore } from '@reduxjs/toolkit';

import authReducer from './slices/authSlice';
import availableModulesReducer from './slices/availableModulesSlice';
import surveysSlice from './slices/surveysSlice';
import {setupListeners} from "@reduxjs/toolkit/query";
import meetingsSlice from "./slices/meetingsSlice";
import {commonApi} from './queries/common.api';
import projects from './slices/projects';
import project from './slices/project';
import initiative from './slices/initiative';
import GVKSlice from "./slices/gvk/GVKSlice";
import landingConstructor from "./slices/landingConstructor";
//REDUCER IMPORT

export const store = configureStore({
  reducer: {
    auth: authReducer,
    surveys: surveysSlice,
    availableModules: availableModulesReducer,
    projects: projects,
    project: project,
    initiative,
    landingConstructor,
    gvk: GVKSlice,
    meetings: meetingsSlice,
    [commonApi.reducerPath] : commonApi.reducer
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false}).concat(commonApi.middleware)
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
