import React from 'react';
import styles from './index.module.scss';
import {NotificationMessage} from "../../../../../models/notifications/INotificationsDTO";
import {timeAgo} from "../../../../../utils";
import cn from "classnames";
import {Button} from "../../../../UI-Lib";
import {ReactComponent as ChatIcon} from "../../../../../img/Sberboost/assets/chatIcon.svg";
import {ReactComponent as CommentIcon} from "../../../../../img/Sberboost/assets/commentIcon.svg";
import {ReactComponent as InitiativeProcess} from "../../../../../img/Sberboost/assets/IinitiativeProcess.svg";
import {useNavigate} from "react-router-dom";
import {setNotificationsScreenOpen} from "../../../../../redux/slices/initiative";
import {useDispatch} from "react-redux";

interface Body {
	content:       Content;
	schemaVersion: SchemaVersion;
}

interface Content {
	text:           string;
	status:         string;
	commentId:      string;
	creationTime:   string;
	authorFullName: string;
	discussionInfo: DiscussionInfo;
}

interface DiscussionInfo {
	id:   string;
	type: string;
}

interface SchemaVersion {
	type:    string;
	version: string;
}


interface IProps {
	notification: NotificationMessage;
	readNotification: Function
}

const NotificationCard:React.FC<IProps> = ({notification, readNotification}) => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	// Сама инициатива
	const subject = notification.message.subject;

	const closePanel = () => {
		dispatch(setNotificationsScreenOpen(false))
	}

	// уведомление
	const body : Body = JSON.parse(notification.message.body);

	const handleClick = () => {
		// сделали прочитанным
		readNotification(notification.id);

		// перешли в раздел
		navigate(`/sberboost/${notification.message.subject.id}?contentType=${
			notification.message.category === 'PUBLIC_DISCUSSION_COMMENT' ? "COMMENTS" :
				notification.message.category === 'PRIVATE_DISCUSSION_COMMENT' ? "PRIVATE_COMMENTS" :
					notification.message.category === 'SYSTEM_DISCUSSION_COMMENT' ? "SYSTEM_COMMENTS" :
					'DATA'
			}`
		)

		// закрыли панель уведомлений
		closePanel();
	}

	return (
		<div className={styles.main}>
			<div className={styles.title}>
				{<div className={cn([styles.indicator, !notification.read && styles.active])}/>}
				<span>Инициатива  “{subject.title}”</span>
			</div>
			<div className={styles.content}>
				<div className={styles.icon}>
					{
						notification.message.category === 'PUBLIC_DISCUSSION_COMMENT' ? <CommentIcon/>
							: notification.message.category === 'PRIVATE_DISCUSSION_COMMENT' ? <ChatIcon/>
								: notification.message.category === 'SYSTEM_DISCUSSION_COMMENT' ? <InitiativeProcess/>
									: <CommentIcon/>
					}
				</div>
				<div className={styles.message}>
					<div className={styles.author}>{body.content.authorFullName ? body.content.authorFullName : "Неизвестный пользователь"}</div>
					<div className={styles.text}>{body.content.text}</div>
					<div className={styles.container}>
						<div className={styles.date}>{timeAgo( body.content.creationTime || '')}</div>
						<Button type="no-fill" className={styles.redirect} onClick={handleClick}>
							{
								notification.message.category === 'PUBLIC_DISCUSSION_COMMENT' ? "Перейти в комментарии"
									: notification.message.category === 'PRIVATE_DISCUSSION_COMMENT' ? "Перейти в чат"
										: "Перейти в комментарии"
							}
						</Button>
					</div>

					{ !notification.read && <Button className={styles.btn} type="no-fill" onClick={() => readNotification(notification.id)}>Отметить прочитанным</Button>}
				</div>
			</div>
		</div>
	);
};

export { NotificationCard };