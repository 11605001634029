import {Link} from 'react-router-dom';
import styles from './index.module.scss';

interface IProps {
  imgUrl: string
  title: string
  urlLink: string
}

const ModuleOfPlatform = ({ imgUrl, title, urlLink } : IProps) => {
  return (
    <Link to={urlLink} className={ styles.main }>
      <div className={ styles.image }>
        <img src={ imgUrl } alt=""/>
      </div>

      <div className={ styles.title }>
        { title }
      </div>
    </Link>
  );
};

export { ModuleOfPlatform };