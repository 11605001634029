import React from 'react';
import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './index.module.scss';

interface IProps {
  value: string
  handleChange: Function
  handleBlur?: Function
  handleClick?: Function
  icon?: React.ReactNode
  iconEnd?: React.ReactNode
  many?: boolean
  autofocus?: boolean
  placeholder?: string
  className?: string
  disabled?: boolean
  type?: string
  name?: string
  minForTypeNumber?: number
  maxForTypeNumber?: number
  minRowsInManyMode?: number
  maxLength?: number
}

const TextField = ({ value, handleChange, handleBlur, handleClick, icon, iconEnd, many, autofocus, placeholder, className, disabled, type, name, minForTypeNumber, maxForTypeNumber, minRowsInManyMode, maxLength} : IProps) => {
  return (
    <div className={ cn([styles.main, disabled && styles.disabled, className]) } onClick={() => handleClick && !disabled ? handleClick() : undefined}>
      { icon }
      {
        many
        ? <TextareaAutosize
            maxLength={maxLength}
            role="textbox"
            disabled={disabled}
            placeholder={ placeholder }
            onChange={(e) => handleChange(e.target?.value || '')}
            onBlur={(e) => handleBlur ? handleBlur(e) : undefined }
            value={value}
            minRows={minRowsInManyMode}
          />
        : <input
            type={type}
            min={(type === 'number' && minForTypeNumber !== undefined) ? minForTypeNumber : undefined}
            max={(type === 'number' && maxForTypeNumber !== undefined) ? maxForTypeNumber : undefined}
            maxLength={maxLength}
            name={name}
            autoFocus={autofocus}
            value={value}
            onChange={ (e) => handleChange(e.target.value) }
            onBlur={ (e) => handleBlur ? handleBlur(e) : undefined }
            placeholder={placeholder}
            disabled={ disabled }
          />
      }
      { iconEnd }
    </div>
  );
};

export { TextField };