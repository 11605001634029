import React from 'react';
import { Route } from 'react-router-dom';
import { AvailableRoute } from '../../AvailableRoute';

const AI = React.lazy(() => import('../index').then(({ AiModule }) => ({ default: AiModule })));

const AiRoutes = (
  <Route path="ai" element={<AvailableRoute moduleName="ai" />}>
    <Route index element={<AI/>} />
  </Route>
);

export { AiRoutes };
