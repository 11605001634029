import React from 'react';
import styles from "./index.module.scss";

const BrowserForm = () => {
	return (
		<div className={styles.main}>
			<div className={styles.topLine}>
				<div className={styles.fakeBtn}>
					<div className={styles.btn} style={{backgroundColor: "rgba(255, 96, 88, 1)"}}/>
					<div className={styles.btn} style={{backgroundColor: "rgba(255, 193, 48, 1)"}}/>
					<div className={styles.btn} style={{backgroundColor: "rgba(39, 202, 64, 1)"}}/>
				</div>
				<div className={styles.title}> Title </div>
			</div>
			<div className={styles.bottomLine}>
				<div className={styles.fakeBtn}>
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M11.2299 5.07685H2.62795L6.38601 1.31879C6.68635 1.01845 6.68635 0.52559 6.38601 0.225253C6.08567 -0.0750842 5.60051 -0.0750842 5.30018 0.225253L0.225253 5.30018C-0.0750842 5.60051 -0.0750842 6.08567 0.225253 6.38601L5.30018 11.4609C5.60051 11.7613 6.08567 11.7613 6.38601 11.4609C6.68635 11.1606 6.68635 10.6754 6.38601 10.3751L2.62795 6.61704H11.2299C11.6535 6.61704 12 6.2705 12 5.84694C12 5.42339 11.6535 5.07685 11.2299 5.07685Z" fill="#5F6368"/>
					</svg>
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M0.770095 6.61704H9.37205L5.61399 10.3751C5.31365 10.6754 5.31365 11.1683 5.61399 11.4686C5.91433 11.769 6.39949 11.769 6.69982 11.4686L11.7747 6.39371C12.0751 6.09337 12.0751 5.60821 11.7747 5.30788L6.70752 0.225253C6.40719 -0.0750842 5.92203 -0.0750842 5.62169 0.225253C5.32135 0.52559 5.32135 1.01075 5.62169 1.31109L9.37205 5.07685H0.770095C0.346543 5.07685 0 5.42339 0 5.84694C0 6.2705 0.346543 6.61704 0.770095 6.61704Z" fill="#BABCBE"/>
					</svg>
				</div>
				<div className={styles.AddressBar}>
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M9 4H8.5V3C8.5 1.62 7.38 0.5 6 0.5C4.62 0.5 3.5 1.62 3.5 3V4H3C2.45 4 2 4.45 2 5V10C2 10.55 2.45 11 3 11H9C9.55 11 10 10.55 10 10V5C10 4.45 9.55 4 9 4ZM4.5 4V3C4.5 2.17 5.17 1.5 6 1.5C6.83 1.5 7.5 2.17 7.5 3V4H4.5Z" fill="#5F6368"/>
					</svg>
					<span className={styles.link}>sbidea.ru/LandingConstructor</span>
				</div>
			</div>
		</div>
	);
};

export {BrowserForm};