import {lazy} from 'react';
import {Route} from 'react-router-dom';
import {AvailableRoute} from '../../AvailableRoute';
import {LandingSettings} from "../LandingSettings";
import {CheckAuth} from "../../../HOC/CheckAuth";

const LandingMarketplace = lazy(() => import('../LandingMarketplace').then(({LandingMarketplace}) => ({ default: LandingMarketplace })));
const LandingCreation = lazy(() => import('../LandingCreation').then( ({LandingCreation}) => ({ default: LandingCreation })));
const LandingEditor = lazy(() => import('../LandingEditor').then(({LandingEditor}) => ({default: LandingEditor})));

const LandingConstructorRoutes = (
    <Route path='/landing-constructor' element={<AvailableRoute moduleName='landingConstructor'/>}>
      <Route index element={<CheckAuth><LandingMarketplace/></CheckAuth>}/>
      <Route path=":id">
        <Route index element={<CheckAuth><LandingSettings/></CheckAuth>}/>
      </Route>
      <Route path='create'>
        <Route index element={<CheckAuth><LandingCreation/></CheckAuth>}/>
        <Route path=":id" element={<CheckAuth><LandingEditor/></CheckAuth>}/>
      </Route>
    </Route>
);

export { LandingConstructorRoutes }