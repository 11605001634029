import { instance } from '../index';
import { store, RootState } from '../../redux/storeToolkit';
import { setRefreshInProgress, resetRefreshInProgress } from 'redux/slices/authSlice';
import {IUserLoginPasswordParamsDTO} from './models/IUserLoginPasswordParamsDTO';
import {IUserTokenDTO} from './models/IUserTokenDTO';
import {AxiosResponse} from 'axios';
import {ITicketForAuthLegacy} from './models/ITicketForAuthLegacy';

interface IAuthAPI {
  getUserToken: (user: IUserLoginPasswordParamsDTO) => Promise<IUserTokenDTO>;
  getGuestToken: () => Promise<IUserTokenDTO>;
  getSudirToken: (searchString: string) => Promise<IUserTokenDTO>;
  refreshToken: () => Promise<IUserTokenDTO>;
  logout: () => Promise<any>;
  getTicketForAuthLegacy: (target: string, codeChallenge: string, codeChallengeMethod: string) => Promise<AxiosResponse<ITicketForAuthLegacy>>
}

export const index: IAuthAPI = {
  getUserToken(user: IUserLoginPasswordParamsDTO): Promise<IUserTokenDTO> {
    return instance
      .post('/authorization-test/auth-from-front', user, {timeout: 20000})
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getGuestToken(): Promise<IUserTokenDTO> {
    return instance
      .post('/authorization/auth-guest',undefined,{timeout:20000})
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getSudirToken(searchString: string): Promise<IUserTokenDTO> {
    return instance
      .get(`/authorization/oidc${searchString}`,{timeout: 20000})
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  refreshToken(): Promise<IUserTokenDTO> {
    if ((store.getState() as RootState).auth.refreshInProgress) return Promise.reject(); // проверяем, запущен ли процесс обновления токена
    store.dispatch(setRefreshInProgress()); // устанавливаем флаг, что процесс обновления запущен
    return instance
      .post('/authorization/refresh', undefined, { withCredentials: true, timeout: 20000 })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      })
      .finally(
        () => store.dispatch(resetRefreshInProgress()) // сбрасываем флаг запуска процесса обновления
      );
  },

  logout(): Promise<any> {
    return instance
      .post('/authorization/logout', undefined, {timeout: 20000})
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getTicketForAuthLegacy(target, codeChallenge, codeChallengeMethod): Promise<AxiosResponse<ITicketForAuthLegacy>> {
    const body = {
      codeChallenge,
      codeChallengeMethod,
      target
    };
    return instance
      .post('/authorization/legacy/sign-in', body)

    /**
     * заглушка пока бэк не был готов
     */
    // return new Promise<AxiosResponse<ITicketForAuthLegacy>>(
    //   (resolve) => (
    //     resolve({
    //       data: {
    //         authorizationProcessId: '0315659f-67ab-48ab-a797-d9c6ed8cc1cb',
    //         ticket: 'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiI5MTQ3MzI3ZS1jZDA3LTRkODMtODk3Yy1hZDYzMGZlZTYxNjciLCJhdXRob3JpemF0aW9uX3Byb2Nlc3NfaWQiOiIwMzE1NjU5Zi02N2FiLTQ4YWItYTc5Ny1kOWM2ZWQ4Y2MxY2IiLCJ1c2VyX2VtYWlsIjoiQUFLdXRlcmluQHNiZXJiYW5rLnJ1IiwidXNlcl9lbXBsb2VlX251bWJlciI6MTIzNDU2NywiZXhwIjoxNjcwNTI4ODg1fQ.ENLbi5qqdbXhC0hWUfh59FvoXwQxRt61Wfmy-KNEEQbpKs5YVL2anxPeRmUuKNMWauI046bv-ZRSHej1CaisGw'
    //       },
    //       status: 200,
    //       statusText: 'OK',
    //       headers: null,
    //       config: {},
    //       request: {}
    //       })
    //   )
    // )
  }
};
