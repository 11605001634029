import {useSelector} from 'react-redux';
import {RootState} from '../redux/storeToolkit';
import {Navigate} from 'react-router-dom';

interface IProps {
  children: JSX.Element
}

const CheckAuth = ({ children } : IProps) => {
  const isAuth = useSelector((state: RootState) => state.auth.role) === 'REGISTERED';

  if (!isAuth) return <Navigate to="/auth" replace={true} />;
  return children;
};

export { CheckAuth };