import {useEffect, useState} from 'react';
import {IStatisticsIndicator} from '../../../models/homepage/IStatisticsIndicator';
import {homePageApi} from '../../../api/homePageApi';
import {Indicator} from './Indicator';
import {declOfNum} from '../../../utils';
import styles from './index.module.scss';

const Statistics = () => {
  const [indicators, setIndicators] = useState<Array<IStatisticsIndicator>>([]);

  useEffect(
    () => {
      const getStatisticsIndicatorsAsync = async () => {
        try {
          setIndicators((await homePageApi.getStatisticsIndicators()).data);
        } catch (e) {}
      }
      getStatisticsIndicatorsAsync();
    }
  ,[])

  return (
    <div className={ styles.main }>
      <div className={ styles.title }>
        За I квартал 2023 года у нас
      </div>

      <div className={ styles.indicators }>
        <Indicator
          imgUrl='https://sbidea.ru/attachments/1/resized_for_show_de/6505ea-4501-46c2-b051-ca8a854abf1c/star.png'
          count={ indicators.find(indicator => indicator.id === 'comments')?.value || 0 }
          label={ declOfNum(indicators.find(indicator => indicator.id === 'comments')?.value || 0, ['комментарий', 'комментария', 'комментариев']) }
        />

        <div className={ styles.separator }/>

        <Indicator
          imgUrl='https://sbidea.ru/attachments/1/resized_for_show_69/f8c695-1885-4b71-a712-5f1486fc2fcf/hand.png'
          count={ indicators.find(indicator => indicator.id === 'ideas')?.value || 0 }
          label={ declOfNum(indicators.find(indicator => indicator.id === 'ideas')?.value || 0, ['идея', 'идеи', 'идей']) }
        />

        <div className={ styles.separator }/>

        <Indicator
          imgUrl='https://sbidea.ru/attachments/1/resized_for_show_c0/89e3b1-29be-443b-8ff3-138860ce0535/cup.png'
          count={ indicators.find(indicator => indicator.id === 'heroes')?.value || 0 }
          label={ declOfNum(indicators.find(indicator => indicator.id === 'heroes')?.value || 0, ['герой', 'героя', 'героев']) }
        />
      </div>
    </div>
  );
};

export { Statistics };