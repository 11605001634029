import {IBadge} from '../../../../models/homepage/IBadge';
import React, {useState} from 'react';
import {ReactComponent as MaleAvatar} from '../../../../img/icons/male_avatar.svg';
import {ReactComponent as FemaleAvatar} from '../../../../img/icons/female_avatar.svg';
import styles from './index.module.scss';

interface IProps {
  badge: IBadge
}
/**
 * бейдж отдельного человека
 * @constructor
 */
const Badge = ({ badge } : IProps) => {
  const [imageError, setImageError] = useState(false); // признак ошибки загрузки аватарки

  return (
    <div className={ styles.main }>
      <div className={ styles.name }>{ badge.name }</div>

      <div className={ styles.surname }>{ badge.surname }</div>

      <div className={ styles.department }>{`(${badge.department})`}</div>

      <div className={ styles.image }>
        {!imageError && badge.avatar ? (
          <img
            src={badge.avatar}
            onError={(e: any) => {
              e.target.onError = null;
              setImageError(true);
            }}
            alt=""
          />
        ) : (
          badge.female
          ? <FemaleAvatar/>
          : <MaleAvatar/>
        )}
      </div>

      {/*достижение*/}
      <div className={ styles.achievement }>
        <div className={ styles.achievement_text }>{ badge.achievement }</div>
      </div>
    </div>
  );
};

export { Badge };