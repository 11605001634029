import styles from './index.module.scss';

const BannerIEDetected = () => {
  return (
    <div className={ styles.main }>
      <img src="/img/yandex_browser_logo.png" alt="" />
      <span>Для корректной работы сайта, пожалуйста, используйте&nbsp;</span>
      <span className={ styles.bold }>Яндекс браузер</span>
    </div>
  );
};

export { BannerIEDetected };
