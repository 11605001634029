import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { AvailableRoute } from '../../AvailableRoute';

const Projects = React.lazy(() => import('../ProjectMarketplace').then(({ ProjectMarketplace }) => ({ default: ProjectMarketplace })));
const EditProjectLocal = React.lazy(() => import('../EditProject').then(({ EditProject }) => ({ default: EditProject })));
const Project = React.lazy(() => import('../Project').then(({ Project }) => ({ default: Project })));
// const InitiativeCreatePage = React.lazy(() => import('../Project/ProjectWithInitiatives/InitiativeCreatePage').then( ({InitiativeCreatePage}) => ({default: InitiativeCreatePage})));
// const InitiativeView = React.lazy(() => import('../Project/ProjectWithInitiatives/InitiativeView').then(({ InitiativeView }) => ({ default: InitiativeView })));
// const InitiativeLandingPage = React.lazy(() => import('../Project/ProjectWithInitiatives/InitiativeLandingPage').then( ({InitiativeLandingPage}) => ({default: InitiativeLandingPage})));
// const SberBoostRules = React.lazy(() => import('../Project/ProjectWithInitiatives/SberBoostRules').then(({SberBoostRules}) => ({default: SberBoostRules})));
// const InitiativeExpertize = React.lazy(() => import('../Project/ProjectWithInitiatives/InitiativeExpertize').then(({ InitiativeExpertize }) => ({ default: InitiativeExpertize })));
// const ParticipantsInstruction = React.lazy(() => import('../Project/ProjectWithInitiatives/InitiativeLandingPage/Instructions/ParticipantInstruction').then(({ParticipantsInstruction}) => ({default: ParticipantsInstruction})));
// const OwnerInstruction = React.lazy(() => import('../Project/ProjectWithInitiatives/InitiativeLandingPage/Instructions/OwnerInstruction').then(({OwnerInstruction}) => ({default: OwnerInstruction})));
// const DPSSInstruction = React.lazy(() => import('../Project/ProjectWithInitiatives/InitiativeLandingPage/Instructions/DPSSInstruction').then(({DPSSInstruction}) => ({default: DPSSInstruction})));
const ProjectRoutes = (
  <Route path="projects" element={<AvailableRoute moduleName="projects"/>}>
    <Route index element={<Projects/>} />
    <Route path=":id">
      <Route index element={<Project/>} />
      <Route path="edit" element={<EditProjectLocal/>}/>
    </Route>

    {/*перенаправим со старого пути проекта на новый*/}
    <Route path="a8838059-3e81-4929-8bcd-03d04512c53a" >
      <Route index path="*" element={<Navigate to="/sberboost/main"/>}/>
    </Route>
  </Route>
);

export { ProjectRoutes };