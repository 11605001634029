import cn from 'classnames';
import styles from './index.module.scss';

interface IProps {
  name: string;
  counter: number;
  active?: boolean;
  setActive: Function;
  classNameTab?: string;
}

const Tab = ({ name, counter, active, setActive, classNameTab }: IProps) => {
  return (
    <div className={cn(styles.main, active && cn(styles.active, classNameTab))} onClick={() => setActive()}>
      <div>{name}</div>
      {counter > 0 && <div className={styles.counter}>{counter}</div>}
    </div>
  );
};

export { Tab };
