import {Link} from 'react-router-dom';
import styles from './index.module.scss';

const BannerIsIdea = () => {
  return (
    <div className={ styles.main }>
      <div className={ styles.banner }>
        <div className={ styles.action }>
          <Link to='/legacy/sign-in?target_uri=https://sbidea.ru/projects'>
            НАЙТИ ПРОЕКТ
          </Link>
        </div>

        <div className={ styles.text_question }>
          <div className={ styles.text_is }>Есть</div>

          <div className={ styles.text_idea }>ИДЕЯ?</div>
        </div>

        <div className={ styles.image }>
          <img src='https://sbidea.ru/attachments/1/resized_for_show_61/de9aa9-bbca-4e2d-a754-c12891f0bbe1/hands_img.png' alt=""/>
        </div>
      </div>
    </div>
  );
};

export { BannerIsIdea };