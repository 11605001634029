import React, { ReactNode, useState } from 'react';
import { RootState } from '../../redux/storeToolkit';
import { setVisibleRuleConstructor } from 'redux/slices/surveysSlice';
import { Menu } from '../UI-Lib';
import { logIn, logOut } from '../../redux/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { IUserInfo } from '../../redux/constants';
import { Button } from '../UI-Lib/ButtonNew';
import { index } from '../../api/authorization';
import styles from './index.module.scss';
import { ReactSVG } from 'react-svg';
import { ModulesMenu } from './ModulesMenu';
import { ReactComponent as Settings } from './settings.svg';
import {
  NotificationIconWidget
} from "../ProjectsSpec/SberBoostProject/InitiativeNotificationsPanel/NotificationIconWidget";
import cn from 'classnames';

interface IProps {
  urlInsteadLogo?: string; // переход на что-либо в левом верхнем углу вместо логотипа
  urlLabel?: string; // подпись ссылки перехода
  children?: React.ReactNode; // что-то, что поместим внутрь заголовка посередине
  childrenInRight?: boolean; // флаг, с помощью которого можно children элементы расположить справа
  hideModuleSwitch?: boolean; // нужно ли отображать переключатель модулей
  settingsIcon?: boolean; // нужно ли отображать шестеренку для включения режима редактирования
  settings?: () => void; // не помню че это, вроде для старого конструктора
  updateLogo?: ReactNode; // что-то вставить вместо лого слева
}

const Header = ({ urlInsteadLogo, urlLabel, hideModuleSwitch, children, childrenInRight, settingsIcon, updateLogo, settings }: IProps) => {
  const isAuth = useSelector((state: RootState) => state.auth.role) === 'REGISTERED';
  const userName = useSelector((state: RootState) => state.auth.name);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const RuleConstructorIsVisible: boolean = useSelector((state: RootState) => state.surveys.constructorIsVisible);
  const isSberboost = window.location.href.match(/sberboost/gi);

  const handleShowMenu = () =>
    // event: React.MouseEvent<HTMLDivElement, MouseEvent>
    {
      // setAnchorEl(event.currentTarget);
      setMenuIsOpen(true);
    };

  const handleClose = () => {
    // setAnchorEl(null);
    setMenuIsOpen(false);
  };

  const handleExit = () => {
    const logout = async () => {
      handleClose();
      try {
        await index.logout();
        dispatch(logOut());
        // полная перезагрузка приложения
        window.location.reload();
      } catch (error) {}

      try {
        const guestToken = (await index.getGuestToken()).accessToken || '';
        const jwtDecoded: any = jwt_decode(guestToken);
        const user: IUserInfo = {
          name: jwtDecoded?.user_name || 'Гость',
          role: jwtDecoded?.user_type || 'Guest',
          access_token: guestToken,
        };
        dispatch(logIn(user));
      } catch (error) {}
    };
    logout();
  };

  const handleLogin = () => {
    const currentPath = location.pathname + location.search;
    if (currentPath.startsWith('/auth')) return; // если мы уже на странице авторизации, то выходим
    // localStorage.setItem('urlRedirect', location.pathname + location.search);
    navigate('/auth'); // посылаем авторизовываться
  };

  // старый способ открытия панели редактирование через стили
  const headerClick = () => {
    // функционал открытия конструктора даём только пользователю test1
    if (userName !== 'test1') return;

    // setServicesOpened(false);
    const constructor = window.document.querySelector('body')?.classList;
    if (constructor) {
      constructor.length === 0 ? constructor.add('none') : constructor.remove('none');
    }
  };

  const openCloseRuleConstructor = () => {
    // функционал открытия конструктора даём только пользователю test1
    if (userName !== 'test1') return;
    dispatch(setVisibleRuleConstructor(!RuleConstructorIsVisible));
  };

  return (
    <header className={cn([styles.main, isSberboost && styles.sberboost])} onClick={headerClick}>
      {/* Кликабельный логотип */}
      <div className={styles.left}>
        {
          updateLogo && urlInsteadLogo ? 
          <Link to={urlInsteadLogo || '/'}>
            {updateLogo}
          </Link>
          :
          urlInsteadLogo ? (
            <Link className={styles.back} to={urlInsteadLogo}>
              <svg className={styles.icon} width={6} height={9} viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.858 0L6 1.058 2.292 4.5 6 7.942 4.858 9 0 4.5 4.858 0z" fill="#757575" />
              </svg>
              <span className={styles.text}>{urlLabel || 'Назад'}</span>
            </Link>
          ) : 
          (
            <Link className={styles.logo} to={'/'}>
              <ReactSVG className={styles.logo_wrapper} src="/img/logo.svg" />
            </Link>
          )
        }
      </div>

      <div className={styles.center}>{!childrenInRight && children}</div>

      {/* Контейнер с кнопками в заголовке */}
      <div className={styles.right}>
        {childrenInRight && children}

        <div className={styles.btns}>
          {userName === 'test1' && settingsIcon && (
            <button className={styles.settings} onClick={() => openCloseRuleConstructor()}>
              <Settings />
            </button>
          )}

          {isSberboost && <NotificationIconWidget/>}

          {!hideModuleSwitch && <ModulesMenu />}

          {isAuth ? (
            <Menu
              menu={
                <div className="__user" onClick={handleShowMenu}>
                  {/* <img src="" alt="" /> */}
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 11C10.7 11 13.8 12.29 14 13V14H2V13.01C2.2 12.29 5.3 11 8 11ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 9C5.33 9 0 10.34 0 13V16H16V13C16 10.34 10.67 9 8 9Z"
                      fill="#525252"
                    />
                  </svg>

                  {/* userName*/}
                </div>
              }
              className={styles.avatar}
              isOpen={menuIsOpen}
              onOuterClick={handleClose}
            >
              <div className={styles['menu-item']} onClick={handleExit}>
                Выйти
              </div>
            </Menu>
          ) : (
            <div>
              <Button type="secondary" onClick={handleLogin}>
                Войти
              </Button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
