import React from 'react';
import styles from './index.module.scss';
import cn from 'classnames';

const Button = (props: any) => {
  return (
    <button {...props} className={cn([styles.idea_button, styles['btn_' + props.type], props.className])}>
      {props.children}
    </button>
  );
};

export { Button };
