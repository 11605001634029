import React from 'react';
import styles from './index.module.scss';
import {ILandingCard} from "../../../../models/landings/constructor/ILandingsDTO/ILandingCard";
import {Button} from "../../../UI-Lib";

interface IProps {
	landing?: ILandingCard
}

const LandingBrowserPreview:React.FC<IProps> = ({landing}) => {
	return (
		<div className={styles.main}>
			<div className={styles.buttons}>
				<div className={styles.toggle}>
					Страница опубликована
				</div>
				<div className={styles.btns}>
					<Button type="no-fill">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M13.8333 13.8333H2.16667V2.16667H8V0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8H13.8333V13.8333ZM9.66667 0.5V2.16667H12.6583L4.46667 10.3583L5.64167 11.5333L13.8333 3.34167V6.33333H15.5V0.5H9.66667Z" fill="#525252"/>
						</svg>
						Открыть страницу
					</Button>
					<Button type="no-fill">
						<svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M2.16667 17.3333C1.70833 17.3333 1.31583 17.1702 0.989167 16.8441C0.663055 16.5175 0.5 16.125 0.5 15.6666V3.99996H2.16667V15.6666H11.3333V17.3333H2.16667ZM5.5 14C5.04167 14 4.64944 13.8369 4.32333 13.5108C3.99667 13.1841 3.83333 12.7916 3.83333 12.3333V2.33329C3.83333 1.87496 3.99667 1.48246 4.32333 1.15579C4.64944 0.829681 5.04167 0.666626 5.5 0.666626H13C13.4583 0.666626 13.8508 0.829681 14.1775 1.15579C14.5036 1.48246 14.6667 1.87496 14.6667 2.33329V12.3333C14.6667 12.7916 14.5036 13.1841 14.1775 13.5108C13.8508 13.8369 13.4583 14 13 14H5.5ZM5.5 12.3333H13V2.33329H5.5V12.3333Z" fill="#525252"/>
						</svg>
						Копировать ссылку
					</Button>
				</div>
			</div>
			<div className={styles.browserPreview}>
				<div className={styles.topLine}>
					<div className={styles.fakeBtn}>
						<div className={styles.btn} style={{backgroundColor: "rgba(255, 96, 88, 1)"}}/>
						<div className={styles.btn} style={{backgroundColor: "rgba(255, 193, 48, 1)"}}/>
						<div className={styles.btn} style={{backgroundColor: "rgba(39, 202, 64, 1)"}}/>
					</div>
					<div className={styles.title}> Title </div>
				</div>
				<div className={styles.bottomLine}>
					<div className={styles.fakeBtn}>
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M11.2299 5.07685H2.62795L6.38601 1.31879C6.68635 1.01845 6.68635 0.52559 6.38601 0.225253C6.08567 -0.0750842 5.60051 -0.0750842 5.30018 0.225253L0.225253 5.30018C-0.0750842 5.60051 -0.0750842 6.08567 0.225253 6.38601L5.30018 11.4609C5.60051 11.7613 6.08567 11.7613 6.38601 11.4609C6.68635 11.1606 6.68635 10.6754 6.38601 10.3751L2.62795 6.61704H11.2299C11.6535 6.61704 12 6.2705 12 5.84694C12 5.42339 11.6535 5.07685 11.2299 5.07685Z" fill="#5F6368"/>
						</svg>
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M0.770095 6.61704H9.37205L5.61399 10.3751C5.31365 10.6754 5.31365 11.1683 5.61399 11.4686C5.91433 11.769 6.39949 11.769 6.69982 11.4686L11.7747 6.39371C12.0751 6.09337 12.0751 5.60821 11.7747 5.30788L6.70752 0.225253C6.40719 -0.0750842 5.92203 -0.0750842 5.62169 0.225253C5.32135 0.52559 5.32135 1.01075 5.62169 1.31109L9.37205 5.07685H0.770095C0.346543 5.07685 0 5.42339 0 5.84694C0 6.2705 0.346543 6.61704 0.770095 6.61704Z" fill="#BABCBE"/>
						</svg>
					</div>
					<div className={styles.AddressBar}>
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M9 4H8.5V3C8.5 1.62 7.38 0.5 6 0.5C4.62 0.5 3.5 1.62 3.5 3V4H3C2.45 4 2 4.45 2 5V10C2 10.55 2.45 11 3 11H9C9.55 11 10 10.55 10 10V5C10 4.45 9.55 4 9 4ZM4.5 4V3C4.5 2.17 5.17 1.5 6 1.5C6.83 1.5 7.5 2.17 7.5 3V4H4.5Z" fill="#5F6368"/>
						</svg>
						<span className={styles.link}>sbidea.ru/LandingConstructor</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export { LandingBrowserPreview };