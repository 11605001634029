import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModuleDTO } from '../../api/systemPreferences/models/IModuleDTO';

const availableModulesSlice = createSlice({
  name: 'system',
  initialState: [] as Array<IModuleDTO>,
  reducers: {
    setAvailableModules(state, action: PayloadAction<Array<IModuleDTO>>) {
      return action.payload;
    },
  },
});

export const { setAvailableModules } = availableModulesSlice.actions;
export default availableModulesSlice.reducer;
