import styles from './index.module.scss';
import {Link} from 'react-router-dom';

const MainBannerOld = () => {
  return (
    <div className={ styles.main }>
      <div className={ styles.banner_mobile }/>
      <div className={ styles.content }>
        <div className={ styles.action }>
          <img src="https://sbidea.ru/attachments/1/resized_for_show_94/3a6b6b-ff0e-480f-8991-33ede2f54caf/lamp.png" alt=""/>
          <Link to='/legacy/sign-in?target_uri=https://sbidea.ru/improject-436302'>
            Идеи
          </Link>
        </div>

        <div className={ styles.text }>
          Здесь описание того что здесь происходит. Для чего нужно делиться идеями, куда они дальше идут, какие есть плюшки за это, что есть уровни “крутости”, топы, рейтинг и т.д. Возможности платформы?
        </div>
      </div>
    </div>
  );
};

export { MainBannerOld };