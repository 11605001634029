import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQuestionWithError, IRuleValue, ISurveysState } from '../constants';
import { IAnswer } from '../../components/Surveys/Survey_v2/types';
import { index } from '../../api/surveysApi';
import {IRule} from '../../models/surveys/Survey/Rule/IRule';
import {IDemoInformation} from '../../models/surveys/Campaigns/IDemoInformation';

// Thunk для создания сессии
export const sessionOpen = createAsyncThunk(
  'surveys/sessionOpen', // отображается в dev tools
  async (campaign: string, { rejectWithValue }) => {
    try {
      return await index.sessionOpen(campaign);
    } catch (error) {
      if (error.response)
        return rejectWithValue({
          message: error.response?.data?.message || 'Ошибка создания сессии. Попробуйте позже',
          status: error.response?.status,
        });
      else return rejectWithValue({ message: 'Ошибка создания сессии. Попробуйте позже', status: 501 });
    }
  }
);

// создаём Thunk для получения актуальных параметров демо-блока и статуса кампании
export const getStatusCampaign = createAsyncThunk(
  'surveys/getStatusCampaign', // отображается в dev tools
  async (campaign: string, { rejectWithValue }) => {
    try {
      return await index.getStatusDemoParams(campaign);
    } catch (error) {
      if (error.response)
        return rejectWithValue({
          message: error.response?.data?.message || 'Ошибка запроса актуальных параметров демо-блока. Попробуйте позже',
          status: error.response?.status,
        });
      else return rejectWithValue({ message: 'Ошибка запроса актуальных параметров демо-блока. Попробуйте позже', status: 501 });
    }
  }
);

const surveysSlice = createSlice({
  name: 'surveys',
  initialState: {
    startLandingPage: '',
    demoParamsIsActual: false,
    demoParams: [],
    userId: '',
    sessionId: '',
    answers: [],
    questionsWithError: [],
    showErrorQuestions: false,
    ruleValues: [],
    rules: [],
    constructorIsVisible: false,
    campaignName: '',
    manualFillingParametersLink: '',
    requestStatus: {},
    currentStageIndexGVK: 0
  } as ISurveysState,
  reducers: {
    setCurrentStageIndexGVK(state, action: PayloadAction<number>) {
      state.currentStageIndexGVK = action.payload;
    },
    saveDemoParams(state, action: PayloadAction<Array<{ name: string; value: string }>>) {
      state.demoParams = action.payload;
    },
    saveUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload;
    },
    saveCampaignStatus(state, action: PayloadAction<IDemoInformation>) {
      state.demoParams = action.payload.actualParameters;
      state.campaignState = action.payload.campaignState;
      state.campaignStateDescription = action.payload.campaignStateDescription;
      state.manualFillingParametersLink = action.payload.manualFillingParametersLink || '';
      state.campaignName = 'campaign';
    },
    needActualiseDemoBlock(state) {
      state.demoParamsIsActual = false;
    },
    setAnswers(state, action: PayloadAction<Array<IAnswer>>) {
      state.answers = action.payload;
    },
    addAnswer(state, action: PayloadAction<IAnswer>) {
      state.answers = [...state.answers.filter((a) => a.questionId !== action.payload.questionId), action.payload];
    },
    delAnswer(state, action: PayloadAction<string>) {
      state.answers = [...state.answers.filter((a) => a.questionId !== action.payload)];
    },
    addQuestionWithError(state, action: PayloadAction<IQuestionWithError>) {
      if (!state.questionsWithError.find((q) => q.id === action.payload.id))
        state.questionsWithError = [...state.questionsWithError, action.payload].sort((a, b) => a.position - b.position);
    },
    delQuestionWithError(state, action: PayloadAction<string>) {
      state.questionsWithError = state.questionsWithError.filter((q) => q.id !== action.payload);
    },
    setShowErrors(state, action: PayloadAction<boolean>) {
      state.showErrorQuestions = action.payload;
    },
    setRuleValues(state, action: PayloadAction<Array<IRuleValue>>) {
      state.ruleValues = [...action.payload];
    },
    setVisibleRuleConstructor(state, action: PayloadAction<boolean>) {
      state.constructorIsVisible = action.payload;
    },
    setRulesList(state, action: PayloadAction<Array<IRule>>) {
      state.rules = [...action.payload];
    },
    setCampaignName(state, action: PayloadAction<string>) {
      state.campaignName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // *** запрос создания сессии ***
      // Вызывается прямо перед выполнением запроса
      .addCase(sessionOpen.pending, (state, action) => {
        // state.loading = true;
        if (!state.requestStatus['sessionOpen']) state.requestStatus['sessionOpen'] = {};
        state.requestStatus['sessionOpen'].loaded = false;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(sessionOpen.fulfilled, (state, action) => {
        // Добавляем информацию
        state.userId = action.payload.userId || '';
        state.requestStatus['sessionOpen'] = {
          loaded: true,
          status: 200,
          message: undefined,
        };
      })
      // Вызывается в случае ошибки
      .addCase(sessionOpen.rejected, (state, action) => {
        // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
        state.requestStatus['sessionOpen'] = {
          loaded: true,
          status: (action?.payload as any)?.status || 501,
          message: action.error.message,
        };
      })
      // *** запрос статуса кампании ***
      // Вызывается прямо перед выполнением запроса
      .addCase(getStatusCampaign.pending, (state, action) => {
        // state.loading = true;
        if (!state.requestStatus['getStatusCampaign']) state.requestStatus['getStatusCampaign'] = {};
        state.requestStatus['getStatusCampaign'].loaded = false;
      })
      // Вызывается в том случае если запрос успешно выполнился
      .addCase(getStatusCampaign.fulfilled, (state, action) => {
        // Добавляем информацию
        state.demoParams = action.payload.actualParameters;
        state.campaignState = action.payload.campaignState;
        state.manualFillingParametersLink = action.payload.manualFillingParametersLink || '';
        state.campaignName = action.meta.arg;
        state.requestStatus['getStatusCampaign'] = {
          loaded: true,
          status: 200,
          message: undefined,
        };
      })
      // Вызывается в случае ошибки
      .addCase(getStatusCampaign.rejected, (state, action) => {
        // https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
        state.requestStatus['getStatusCampaign'] = {
          loaded: true,
          status: (action?.payload as any)?.status || 501,
          message: action.error.message,
        };
      });
  },
});

export const {
  setCurrentStageIndexGVK,
  saveDemoParams,
  saveUserId,
  saveCampaignStatus,
  needActualiseDemoBlock,
  setVisibleRuleConstructor,
  setRulesList,
  setAnswers,
  addAnswer,
  delAnswer,
  addQuestionWithError,
  delQuestionWithError,
  setShowErrors,
  setRuleValues,
  setCampaignName,
} = surveysSlice.actions;
export default surveysSlice.reducer;
