// асинхронная функция задержки
import {IIdeaDTO} from '../models/projects/ideas/idea/IIdeaDTO';
import {IAttributeCalculator} from '../models/projects/ideaSchema/attributeCalculator';
import {IDocumentAttribute} from '../models/projects/ideaSchema/IDocumentAttribute';
import {uuid} from '../components/SurveyConstructor/uuid';
import {
  IAttributeInitiativeCalculator
} from '../models/projects/ideaSchema/attributeCalculator/IAttributeInitiativeCalculator';
import {IAttributeInitiativeUX} from '../models/projects/ideaSchema/attributeCalculator/IAttributeInitiativeUX';

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// функция выдаёт период в виде текста
export const periodTxt = (date0: Date, date1: Date): string => {
  // массив месяцев
  const months = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];

  if (date0 > date1) return '';
  let y0 = 0,
    m0 = 0,
    d0 = 0,
    h0 = 0,
    min0 = 0;
  let y1 = 0,
    m1 = 0,
    d1 = 0,
    h1 = 0,
    min1 = 0;
  try {
    // разбираем начальную дату
    y0 = date0.getFullYear();
    m0 = date0.getMonth();
    d0 = date0.getDate();
    h0 = date0.getHours();
    min0 = date0.getMinutes();

    // разбираем конечную дату
    y1 = date1.getFullYear();
    m1 = date1.getMonth();
    d1 = date1.getDate();
    h1 = date1.getHours();
    min1 = date1.getMinutes();

    const yearIsCurrent = new Date().getFullYear() === y1;
    const yearsAreEquals = y0 === y1;
    const monthsAreEquals = yearsAreEquals && m0 === m1;
    const datesAreEquals = monthsAreEquals && d0 === d1;

    // первая часть даты
    let result =
      d0.toString() +
      ' ' +
      months[m0] +
      ' ' +
      (yearIsCurrent ? '' : y0.toString()) +
      ' ' +
      h0.toString().padStart(2, '0') +
      ':' +
      min0.toString().padStart(2, '0') +
      ' - ';

    if (datesAreEquals) result = result + h1.toString().padStart(2, '0') + ':' + min1.toString().padStart(2, '0');
    else if (yearsAreEquals)
      result = result + d1.toString() + ' ' + months[m1] + ' ' + h1.toString().padStart(2, '0') + ':' + min1.toString().padStart(2, '0');
    else
      result =
        result +
        d1.toString() +
        ' ' +
        months[m1] +
        ' ' +
        y1.toString() +
        ' ' +
        h1.toString().padStart(2, '0') +
        ':' +
        min1.toString().padStart(2, '0');
    return result;
  } catch (error) {
    return '';
  }
};

// Для склонения числовых значений
export const declOfNum = (n: number, text_forms: Array<string>) => {
  n = Math.abs(n) % 100;
  var n1 = n % 10;
  if (n > 10 && n < 20) {
    return text_forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return text_forms[1];
  }
  if (n1 === 1) {
    return text_forms[0];
  }
  return text_forms[2];
};

// функция возвращает строку-период
// входные данные - количество милисекунд
// выходные данные - строка в днях, часах, минутах
export function getDuration(duration: number): string {
  // duration - количество милисекунд
  let minutes: number = Math.trunc(duration / 1000 / 60); // получаем количество минут
  const days: number = Math.trunc(minutes / 60 / 24); // вычисляем количество полных дней
  minutes = minutes % (days * 24 * 60); // остаток минут за вычетом полных дней
  const hours: number = Math.trunc(minutes / 60); // количество часов в оставшихся минутах
  minutes = minutes % (hours * 60); // остаток минут за вычетом часов

  // собираем результат
  return `${days > 0 ? days.toString() + ' ' + declOfNum(days, ['день', 'дня', 'дней']) : ''} 
  ${hours > 0 ? hours.toString() + ' ' + declOfNum(hours, ['час', 'часа', 'часов']) : ''} 
  ${minutes > 0 ? minutes.toString() + ' ' + declOfNum(minutes, ['минута', 'минуты', 'минут']) : ''}`;
}

// первую минуту отображаем Х секунд назад
// если более минуты, но менее получаса, то Х минут назад
// далее отображаем время
export const timeAgo = (time: string): string => {
  // массив месяцев
  const months = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];

  const date1 = new Date();
  const date0 = new Date(time);
  const secCount: number = Math.trunc((+date1 - +date0) / 1000);
  const minutesCount = Math.trunc(secCount / 60);
  if (secCount < 10) return 'только что';
  if (secCount < 60) return `${secCount} ${declOfNum(secCount, ['секунду', 'секунды', 'секунд'])} назад`;
  else if (minutesCount < 30) return `${minutesCount} ${declOfNum(minutesCount, ['минуту', 'минуты', 'минут'])} назад`;
  // время прошло более получаса
  // отобразим время
  try {
    // разбираем начальную дату
    const y0 = date0.getFullYear();
    const m0 = date0.getMonth();
    const d0 = date0.getDate();
    const h0 = date0.getHours();
    const min0 = date0.getMinutes();

    // разбираем конечную дату
    const y1 = date1.getFullYear();
    const m1 = date1.getMonth();
    const d1 = date1.getDate();

    const yearIsCurrent = y0 === y1;
    const monthIsCurrent = yearIsCurrent && m0 === m1;
    const dateIsCurrent = monthIsCurrent && d0 === d1;

    let rez = h0.toString().padStart(2, '0') + ':' + min0.toString().padStart(2, '0'); // время
    if (!dateIsCurrent) rez = d0.toString().padStart(2, '0') + ' ' + months[m0] + (yearIsCurrent ? '' : ' ' + y0.toString()) + ' ' + rez;
    return rez;
  } catch (e) {
    return '';
  }
};

export const getDateFormatted = (dateStr: string, withoutYear?: boolean) => {
  try {
    const date = new Date(dateStr);
    const yyyy = date.getFullYear();
    const months = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
    let mm = date.getMonth();
    let dd = date.getDate();

    if (withoutYear) {
      return `${dd.toString()} ${months[mm]}`;  
    }

    return `${dd.toString()} ${months[mm]} ${yyyy.toString()}`;

  } catch (e) {
    return '--';
  }
};

// функция генерирует случайную строку заданной длины
export const randomString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// функция генерирует секретный ключ PKCE Code Verifier
export const getPKCECodeVerifier = (minLength: number, maxLength: number): string => {
  // допустимые символы
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  // случайным образом генерируем длину ключа
  const length = minLength + Math.round(Math.random() * (maxLength - minLength));
  // результат
  let result = '';
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.round(Math.random() * characters.length));
  }
  return result;
}

export const titles = {
  '/projects' : 'Проекты',
  '/events' : 'События',
  '/survey-constructor/surveys' : 'Конструктор опросов',
  '/survey-campaigns' : 'Кампании опросов',
  '/ai' : 'Виртуальный помощник',
  '/chamomile/' : 'Ромашка',
} as any;

export function bytesToSize(bytes: number) {
  if (!bytes) { return '0 Byte' }
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
}

/**
 * функция добавления новых аттрибутов калькулятора
 */
export const ideaWithNewCalcs = (idea: IIdeaDTO): IIdeaDTO => {
  const old_calculator: IAttributeCalculator = JSON.parse(idea.document?.attributes?.find(attribute => attribute.name === 'calculator')?.value as any || '{}');
  let modifiedIdea: IIdeaDTO = {...idea};
  // добавим калькулятор экономического эффекта
  if (!modifiedIdea?.document?.attributes?.find(attribute => attribute.name === 'calculator_effect_1')) {
    const calculatorEffect: IDocumentAttribute = {
      id: uuid(),
      name: 'calculator_effect_1',
      type: {
        id: uuid(),
        value: 'calculator_effect',
        description: 'Калькулятор эффект'
      },
      layout: 'body',
      value: JSON.stringify({
        resources: {...old_calculator?.resources},
        sales: {...old_calculator?.sales},
        workTime: {...old_calculator?.workTime},
        nonStandard: {...old_calculator.nonStandard}
      } as IAttributeInitiativeCalculator)
    }
    modifiedIdea = {
      ...modifiedIdea,
      document: {
        ...modifiedIdea.document,
        attributes: [...modifiedIdea.document?.attributes || [], calculatorEffect]
      }
    }
    // добавляем улучшение пользовательского опыта
    if (!modifiedIdea.document?.attributes?.find(attribute => attribute.name === 'calculator_ux_upgrade_1')) {
      const calculatorUX: IDocumentAttribute = {
        id: uuid(),
        name: 'calculator_ux_upgrade_1',
        type: {
          id: uuid(),
          value: 'calculator_ux',
          description: 'Калькулятор клиентский опыт'
        },
        layout: 'body',
        value: JSON.stringify({
          clientExp: old_calculator?.clientExp,
          metriki: old_calculator?.metriki
        } as IAttributeInitiativeUX)
      }
      modifiedIdea = {
        ...modifiedIdea,
        document: {
          ...modifiedIdea.document,
          attributes: [...modifiedIdea.document?.attributes || [], calculatorUX]
        }
      }
    }
  }
  return modifiedIdea;
}

// набор месяцев
export const MONTHES: Array<{ fullName: string, shortName: string }> = [
  {
      fullName: 'Январь',
      shortName: 'янв',
  },
  {
      fullName: 'Февраль',
      shortName: 'фев',
  },
  {
      fullName: 'Март',
      shortName: 'мар',
  },
  {
      fullName: 'Апрель',
      shortName: 'апр',
  },
  {
      fullName: 'Май',
      shortName: 'мая',
  },
  {
      fullName: 'Июнь',
      shortName: 'июн',
  },
  {
      fullName: 'Июль',
      shortName: 'июл',
  },
  {
      fullName: 'Август',
      shortName: 'авг',
  },
  {
      fullName: 'Сентябрь',
      shortName: 'сен',
  },
  {
      fullName: 'Октябрь',
      shortName: 'окт',
  },
  {
      fullName: 'Ноябрь',
      shortName: 'ноя',
  },
  {
      fullName: 'Декабрь',
      shortName: 'дек',
  }
];

/**
 * функция определения типа девайся, который мы используем
 */
export const isMobileDevice = () => {
  return window.navigator.userAgent;
}

export function formatDate(dateString?: string): string {

  if (!dateString) {
    return ''
  }
  
  // Создаем объект даты из строки
  const date = new Date(dateString);

  // Получаем день, месяц и год
  const day = String(date.getDate()).padStart(2, '0'); // Добавляем ведущий ноль для однозначных чисел
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0, поэтому добавляем 1
  const year = date.getFullYear();

  // Формируем нужный формат
  return `${day}.${month}.${year}`;
}