import styles from './style.module.scss';
import cn from 'classnames';
import { PopUpBackground } from '../PopUpBackground';
import React from 'react';

type positionVType = 'center' | 'top' | 'bottom';
type positionHType = 'left' | 'center' | 'right';

interface Props {
  menu: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  positionV?: positionVType;
  positionH?: positionHType;
  isOpen?: boolean;
  onOuterClick?: ((event?: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => void) | undefined;
}

export const Menu = ({ children, menu, className, positionH = 'right', positionV = 'bottom', isOpen = false, onOuterClick }: Props) => {
  return (
    <>
      <PopUpBackground onClick={onOuterClick} visible={isOpen} className={styles['menu__pop-up-background']} samePlace />
      <div className={cn(styles.menu, className)}>
        {menu}
        <div
          className={cn(
            styles['menu__items-container'],
            styles[`menu__items-container_h${positionH}`],
            styles[`menu__items-container_v${positionV}`],
            isOpen && styles['menu__items-container_visible']
          )}
        >
          {children}
        </div>
      </div>
    </>
  );
};
