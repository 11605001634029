import React from 'react';

const Notifications = () => {
	return (
		<div>
			Уведомления
		</div>
	);
};

export { Notifications };