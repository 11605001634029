import styles from './index.module.scss';

interface IProps {
  imgUrl: string
  count: number
  label: string
}

const Indicator = ({ imgUrl, count, label } : IProps) => {
  return (
    <div className={ styles.main }>
      <div className={ styles.image }>
        <img src={ imgUrl } alt=""/>
      </div>

      <div className={ styles.txt_block }>
        <div className={ styles.count }>{ count }</div>

        <div className={ styles.label }>{ label }</div>
      </div>
    </div>
  );
};

export { Indicator };