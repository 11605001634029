import { Route } from 'react-router-dom';
import { AvailableRoute } from '../../AvailableRoute';
import {lazy} from 'react';
import {CheckAuth} from '../../../HOC/CheckAuth';

const MeetingMarketplace = lazy(() => import('../MeetingMarketplace').then(({ MeetingMarketplace }) => ({ default: MeetingMarketplace })));
const Meeting = lazy(() => import('../Meeting').then(({ Meeting }) => ({ default: Meeting })));
const MeetingEditPage = lazy(() => import('../MeetingEditPage').then(({ MeetingEditPage }) => ({ default: MeetingEditPage })));

const MeetingsRoutes = (
  <Route path='meetings' element={<AvailableRoute moduleName='meetings' />}>
    <Route index element={<CheckAuth><MeetingMarketplace/></CheckAuth>}/>
    <Route path=':id'>
      <Route index element={<Meeting/>}/>
      <Route path='edit' element={<MeetingEditPage/>}/>
    </Route>
    <Route path='template'>
      <Route path=':id' element={<MeetingEditPage isTemplate={true}/>}/>
    </Route>
  </Route>
);

export { MeetingsRoutes };
