import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserInfo } from '../constants';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    name: '',
    role: '',
    access_token: '',
    refreshInProgress: false,
  } as IUserInfo,
  reducers: {
    logIn(state, action: PayloadAction<IUserInfo>) {
      state.name = 'payload' in action ? action.payload.name : '';
      state.role = 'payload' in action ? action.payload.role : '';
      state.access_token = 'payload' in action ? action.payload.access_token : '';
    },
    logOut(state) {
      state.name = '';
      state.role = '';
      state.access_token = '';
    },
    setRefreshInProgress(state) {
      state.refreshInProgress = true;
    },
    resetRefreshInProgress(state) {
      state.refreshInProgress = false;
    },
  },
});

export const { logIn, logOut, setRefreshInProgress, resetRefreshInProgress } = authSlice.actions;
export default authSlice.reducer;
