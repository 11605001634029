import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { AvailableRoute } from '../../AvailableRoute';

const Survey = lazy(() => import('../../Surveys/Survey').then(({ Survey }) => ({ default: Survey })));
const SurveyCampaigns = lazy(() => import('../index').then(({ SurveyCampaigns }) => ({ default: SurveyCampaigns })));
// const SurveyCampaign = lazy(() => import('../SurveyCampaign').then(({ SurveyCampaign }) => ({ default: SurveyCampaign })));
const SurveyCampaignsControl = lazy(() => import('../SurveyCampaignsControl').then(({ SurveyCampaignsControl }) => ({ default: SurveyCampaignsControl })));

const SurveyCampaignsRoutes = (
  <Route path="survey-campaigns" element={<AvailableRoute moduleName="surveyCampaigns" />}>
    <>
      <Route index element={<SurveyCampaigns />} />
      {/*<Route path="results" element={<Results/>}/>*/}
      <Route path=":campaignId">
        <Route index element={<SurveyCampaignsControl/>} />
        {/*<Route index element={<SurveyCampaign/>} />*/}
        <Route path=":id" element={<Survey/>} />
      </Route>
    </>
  </Route>
);
export { SurveyCampaignsRoutes };
