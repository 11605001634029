import React from 'react';

const Agreement = () => {
	return (
		<div>
			Соглашение
		</div>
	);
};

export {Agreement};