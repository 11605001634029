import React, { useState } from 'react';
import styles from './index.module.scss';
import { PopUpBackground } from '../../UI-Lib';
import cn from 'classnames';
import { allModules } from '../../Home';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/storeToolkit';
import { IModuleDTO } from '../../../api/systemPreferences/models/IModuleDTO';

const ModulesMenu = () => {
  const [servicesOpened, setServicesOpened] = useState<boolean>(false);
  const availableModules: Array<IModuleDTO> = useSelector((state: RootState) => state.availableModules);

  return (
    <div className={styles.main}>
      {/* Кнопка переключения между сервисами */}
      <button
        className={styles.btn}
        onClick={(e) => {
          setServicesOpened(!servicesOpened);
          e.stopPropagation();
        }}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 7.5C4 7.77614 4.22386 8 4.5 8H7.5C7.77614 8 8 7.77614 8 7.5V4.5C8 4.22386 7.77614 4 7.5 4H4.5C4.22386 4 4 4.22386 4 4.5V7.5ZM10 19.5C10 19.7761 10.2239 20 10.5 20H13.5C13.7761 20 14 19.7761 14 19.5V16.5C14 16.2239 13.7761 16 13.5 16H10.5C10.2239 16 10 16.2239 10 16.5V19.5ZM4 19.5C4 19.7761 4.22386 20 4.5 20H7.5C7.77614 20 8 19.7761 8 19.5V16.5C8 16.2239 7.77614 16 7.5 16H4.5C4.22386 16 4 16.2239 4 16.5V19.5ZM4 13.5C4 13.7761 4.22386 14 4.5 14H7.5C7.77614 14 8 13.7761 8 13.5V10.5C8 10.2239 7.77614 10 7.5 10H4.5C4.22386 10 4 10.2239 4 10.5V13.5ZM10 13.5C10 13.7761 10.2239 14 10.5 14H13.5C13.7761 14 14 13.7761 14 13.5V10.5C14 10.2239 13.7761 10 13.5 10H10.5C10.2239 10 10 10.2239 10 10.5V13.5ZM16.5 4C16.2239 4 16 4.22386 16 4.5V7.5C16 7.77614 16.2239 8 16.5 8H19.5C19.7761 8 20 7.77614 20 7.5V4.5C20 4.22386 19.7761 4 19.5 4H16.5ZM10 7.5C10 7.77614 10.2239 8 10.5 8H13.5C13.7761 8 14 7.77614 14 7.5V4.5C14 4.22386 13.7761 4 13.5 4H10.5C10.2239 4 10 4.22386 10 4.5V7.5ZM16 13.5C16 13.7761 16.2239 14 16.5 14H19.5C19.7761 14 20 13.7761 20 13.5V10.5C20 10.2239 19.7761 10 19.5 10H16.5C16.2239 10 16 10.2239 16 10.5V13.5ZM16 19.5C16 19.7761 16.2239 20 16.5 20H19.5C19.7761 20 20 19.7761 20 19.5V16.5C20 16.2239 19.7761 16 19.5 16H16.5C16.2239 16 16 16.2239 16 16.5V19.5Z"
            fill={servicesOpened ? '#5099f6' : '#575757'}
          />
        </svg>

        {/* Бэкграунд для того, чтоб при щелчке вне меню оно закрывалось */}
        <PopUpBackground
          visible={servicesOpened}
          onClick={() => setServicesOpened(false)}
          className={styles['pop-up-background']}
          samePlace
        />

        {/* само меню */}
        <div className={styles.services} style={{ display: servicesOpened ? 'block' : 'none' }}>
          <div className={styles.label}>Переключиться на...</div>

          <div className={styles['link-container']}>
            {availableModules.map((module) => {
              if (module.display === 'SHOW_ACTIVE' || module.display === 'SHOW_INACTIVE') {
                // нужно ли его показывать в каком-либо виде
                if (module.url?.startsWith('http'))
                  return (
                    <a
                      href={module.url}
                      className={cn(styles.link, module.display === 'SHOW_INACTIVE' && styles['link-hidden'])}
                      key={module.url}
                      onClick={() => setServicesOpened(false)}
                    >
                      <img
                        src={
                          allModules.filter((m) => m.id === module.id).length > 0 ? allModules.filter((m) => m.id === module.id)[0].img : ''
                        }
                        alt=""
                      />
                      <div className={styles['link-name']}>
                        {allModules.filter((m) => m.id === module.id).length > 0
                          ? allModules.filter((m) => m.id === module.id)[0].title
                          : module.id}
                      </div>
                    </a>
                  );
                else
                  return (
                    <NavLink
                      className={cn(styles.link, module.display === 'SHOW_INACTIVE' && styles['link-hidden'])}
                      to={module.url || ''}
                      key={module.id || ''}
                      onClick={() => setServicesOpened(false)}
                    >
                      <img
                        src={
                          allModules.filter((m) => m.id === module.id).length > 0 ? allModules.filter((m) => m.id === module.id)[0].img : ''
                        }
                        alt=""
                      />
                      <div className={styles['link-name']}>
                        {allModules.filter((m) => m.id === module.id).length > 0
                          ? allModules.filter((m) => m.id === module.id)[0].title
                          : module.id}
                      </div>
                    </NavLink>
                  );
              } else
                return <React.Fragment key={module.id}/>
            })}

            <div className={styles.delimiter}/>

            <NavLink className={styles.link} to={'/'} onClick={() => setServicesOpened(false)}>
              Все сервисы
            </NavLink>
          </div>
        </div>
      </button>
    </div>
  );
};

export { ModulesMenu };