import React from 'react';

// компонент отображения надписи
const LabelRequired = ({ label, required, className }: any) => (
  <div className={'idea_input_label ' + className}>
    {label}
    {required && <span> *</span>}
  </div>
);

const LabelRequiredHOC =
  (Child: React.ComponentType) =>
  ({ label, required, className, ...props }: any) => {
    return (
      <div className={className}>
        <LabelRequired label={label} required={required} />
        <Child {...props} required />
      </div>
    );
  };

export { LabelRequiredHOC, LabelRequired };
