import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { sanitize } from 'dompurify';

export type AvatarType = 'rounded' | 'circle';
export type AvatarSize = 'small' | 'medium';

interface Props {
  image: string;
  type?: AvatarType;
  size?: AvatarSize;
  link?: string;
  alt?: string;
  className?: string;
}

export const Avatar = ({ image, type = 'circle', size = 'small', link, alt = 'ava', className }: Props) => {
  const [imageError, setImageError] = useState<boolean>(false);

  if (imageError) return <></>;

  return link ? (
    <Link to={link} className={className}>
      <img
        className={`avatar avatar_${type} avatar_${size}`}
        src={sanitize(image, { USE_PROFILES: { html: true } })}
        onError={(event) => {
          setImageError(true);
        }}
        alt={alt}
      />
    </Link>
  ) : (
    <img
      className={`avatar avatar_${type} avatar_${size}`}
      src={sanitize(image, { USE_PROFILES: { html: true } })}
      onError={(event) => {
        setImageError(true);
      }}
      alt={alt}
    />
  );
};
