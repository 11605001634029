import {lazy} from 'react'
import {Navigate, Route} from 'react-router-dom';
import {AvailableRoute} from '../../AvailableRoute';

const Surveys = lazy(() => import('../').then(({Surveys}) => ({default: Surveys})));
const Survey = lazy(() => import('../Survey_v2').then(({Survey}) => ({default: Survey})));
const Admin = lazy(() => import('../Admin').then(({Admin}) => ({default: Admin})));

const SurveysRoutes = (
	<Route path="surveys" element={<AvailableRoute moduleName="surveyCampaigns"/>}>
		<Route index element={<Surveys/>}/>
		<Route path=":id" element={<Survey/>}/>
		<Route path="system/admgod" element={<Admin/>}/>
		<Route path="*" element={<Navigate to="/surveys" replace/>}/>
	</Route>
);

export {SurveysRoutes};