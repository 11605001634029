import {Route} from 'react-router-dom';
import {AvailableRoute} from '../../AvailableRoute';
import Status404 from '../../StatusError/Status404';
import {lazy} from 'react';

const AuthForLegacy = lazy(() => import('../index').then(({ AuthForLegacy }) => ({ default: AuthForLegacy })));

const LegacyRoutes = (
  <Route path='legacy' element={<AvailableRoute moduleName='' alwaysAvailable={true}/>}>
    <Route index element={<Status404/>}/>
    <Route path='sign-in' element={<AuthForLegacy/>}/>
  </Route>
)

export { LegacyRoutes }