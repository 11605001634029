import React from 'react';

interface Props {
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  isLoading?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const LoadMoreButton = ({ isLoading, className, onClick, children }: Props) => {
  return (
    <button className={`btn-load-more ${className ? className : ''}`} onClick={onClick} disabled={isLoading}>
      {isLoading ? (
        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="11.5" cy="10.5" r="10.5" fill="#C4C4C4" />
          <circle cx="11.5" cy="10.5" r="8.5" fill="white" />
        </svg>
      ) : children ? (
        children
      ) : (
        ''
      )}
    </button>
  );
};
