import React from 'react';

interface Props {
  className?: string | undefined;
}

export const StoppedPropagination: React.FC<Props> = ({ children }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      {children}
    </div>
  );
};
