import {Route} from 'react-router-dom';
import {AvailableRoute} from '../../AvailableRoute';
import {lazy} from 'react';

const AdminManagement = lazy(() => import('../../AdminManagement').then(({ AdminManagement }) => ({ default: AdminManagement })));

const AdminManagementRoutes = (
  <Route path='admin-ka' element={<AvailableRoute moduleName='' alwaysAvailable={true}/>}>
    <Route index element={<AdminManagement/>}/>
  </Route>
)

export { AdminManagementRoutes }