import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IDocumentSchemaDTO} from '../../../models/projects/ideaSchema/IDocumentSchemaDTO';
import {IActivityInProjectDTO} from '../../../models/projects/activities/IActivityInProjectDTO';
import {IProjectDTO} from '../../../models/projects/project/IProjectDTO';
import {IProjectsSortType} from "../../../models/projects/IProjectsSortType";
import {IIdeaDTO} from "../../../models/projects/ideas/idea/IIdeaDTO";
import {IProjectsSlice} from './IProjectsSlice';
import {IUserPermissionsInProjectsDTO} from '../../../models/projects/IUserPermissionsInProjectsDTO';
import {IProjectMarketplacePagination} from './IProjectMarketplacePagination';
import {IIdeasCapability} from '../../../models/projects/ideas/IIdeasCapability';
import {uuid} from "../../../components/SurveyConstructor/uuid";
import {IDocumentAttribute} from '../../../models/projects/ideaSchema/IDocumentAttribute';
import {IAttributeCalculator} from '../../../models/projects/ideaSchema/attributeCalculator';
import {IAttributeCalculatorCheckBox} from '../../../models/projects/ideaSchema/attributeCalculator/IAttributeCalculatorCheckBox';

const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    /**
     * маркетплейс проектов
     */
    projectMarketplace: {
      // разрешения
      permissions: {},
      // типы проектов
      projectTypes: [],
      // список проектов для отображения в карточках
      projectsList: [],
      // постраничность
      pagination: {
        page: 0,
        perPage: 10
      },
      // опции фильтрации карточек
      filterOptions: {
        search: '',
        projectType: undefined
      },
      // последняя ли страница
      last: true
    },

    /**
     * состояние выбранного проекта
     */
    project: {
      projectData: {},
      activities: [],
      ideas: {
        capabilities: [],
        ideasList: [],
        currentIdea: {}
      },
      ideaSchema: {}
    },

    /**
     * состояние проекта с инициативами
     */
    initiatives: {
      initiativeExpertize: {
        blockInView: ''
      },

      initiativeCurrentForm: {
            fields: [
              {
                id: "initiativeName", // готово
                title: "Название инициативы",
                type: "textField",
                value: ""
              },
              {
                id: "initiativeRegionalBank", // готово
                title: "Территориальный банк",
                type: "dictionaryField",
                value: undefined
              },
              {
                id: "initiativeIssue", // готово
                title: "Опишите проблему",
                type: "textField",
                value: ""
              },
              {
                id: "initiativeResolve", // готово
                title: "Подробно опиши решение",
                type: "textField",
                value: ""
              },
              {
                id: "initiativeTheme", // НЕ готово
                title: "Выбери тематику",
                type: "dictionaryField",
                value: undefined
              },
              {
                id: "resources",
                title: "Экономия материальных ресурсов",
                type: "calculateField",
                text: "",
                value: 0,
                items: [
                  {
                    id: uuid(),
                    title: '',
                    amount: 0,  // кол-во
                    cost: 0,    // стоимость
                    period: {value: 'month', label: 'месяц', multiplier: 12}, // период выбирается из справочника
                    value: 0
                  },
                ]
              },
              {
                id: "sales",
                title: "Рост продаж / Увеличение дохода от продаж",
                type: "calculateEffect",
                text: "",
                value: 0,
                items: [
                  {
                    id: uuid(),
                    title: '',
                    amount: 0,
                    cost: 0,
                    period: {value: 'month', label: 'месяц', multiplier: 12},
                    value: 0
                  },
                ]
              },
              {
                id: "WorkTime",
                title: "Экономия рабочего времени",
                type: "calculateEffect",
                text: "",
                value: 0,
                items: [
                  {
                    id: uuid(),
                    title: '', // Должность сотрудника
                    minutes: 0, // кол-во высвобожденных минут
                    days: 0, // Количество дней в месяц освобождаемых
                    amountOperations: 0, // кол-во операций в день
                    coastMinute: {value: 10.4, label: 'Эффект распространяется на всех сотрудников'}, // Текущая стоимость минуты раб. времени
                    value: 0
                  }
                ]
              },
              {
                id: "NonStandart",
                title: "Эффект с нестандартной логикой расчета",
                type: "calculateEffect",
                text: "",
                value: 0,
                items: [
                  {
                    id: uuid(),
                    title: '',
                    profit: 0, //Доход / экономия
                    period: {value: 'month', label: 'месяц', multiplier: 12}, // период
                    value: 0
                  }
                ]
              },
              {
                id: "clientExp",
                title: "Улучшения клиентского опыта",
                type: "checkBoxes",
                checkboxList: [
                  {
                    id: "1",
                    name: "Уменьшает операционные риски/вероятность ошибок процесса",
                    value: false
                  },
                  {
                    id: "2",
                    name: "Делает покупку или получение услуги/продукта понятнее, прозрачнее, уменьшает вероятность ошибочной покупки",
                    value: false
                  }
                ]
              },
              {
                id: "metriki",
                title: "Критерии метрики «повышение удовлетворенности (CSI, NPS, ГВК»",
                type: "checkBoxes",
                checkboxList: [
                  {
                    id: "1",
                    name: "Уменьшает операционные риски/вероятность ошибок процесса",
                    value: false
                  },
                  {
                    id: "2",
                    name: "Делает покупку или получение услуги/продукта понятнее, прозрачнее, уменьшает вероятность ошибочной покупки",
                    value: false
                  },
                  {
                    id: "3",
                    name: "Делает покупку или получение услуги/продукта удобнее - за счет интуитивно понятного дизайна/интерфейса",
                    value: false
                  },
                  {
                    id: "4",
                    name: "Делает покупку или получение услуги/продукта быстрее - за счет сокращения времени на оказание услуги, исключение повторного ввода, количества бумаг, времени ожидания",
                    value: false
                  },
                  {
                    id: "5",
                    name: "Делает совершение выбора и покупки продукта/получение услуги в комфортных условиях",
                    value: false
                  },
                  {
                    id: "6",
                    name: "Делает понятной покупку или получение услуги/продукта",
                    value: false
                  },
                  {
                    id: "7",
                    name: "Превосходит ожидания клиента после продажи продукта/оказания услуги/сервиса - просто/удобно/быстро получить повторную консультацию, техническую поддержку",
                    value: false
                  }
            ]
          }
        ]
      }
    }
  } as IProjectsSlice,
  reducers: {
    /**
     * обновить разрешения в маркетплейсе для пользователя
     * @param state
     * @param action
     */
    updateMarketplacePermissions(state, action: PayloadAction<IUserPermissionsInProjectsDTO>) {
      state.projectMarketplace = {
        ...state.projectMarketplace,
        permissions: action.payload
      }
    },

    /**
     * обновить список типов проектов для фильтрации маркетплейса
     * @param state
     * @param action
     */
    updateMarketplaceProjectTypes(state, action: PayloadAction<Array<IProjectsSortType>>) {
      state.projectMarketplace = {
        ...state.projectMarketplace,
        projectTypes: action.payload
      }
    },

    /**
     * обновить строку для поиска
     * @param state
     * @param action
     */
    updateMarketplaceSearchString(state, action: PayloadAction<string>) {
      state.projectMarketplace = {
        ...state.projectMarketplace,
        filterOptions: {
          ...state.projectMarketplace.filterOptions,
          search: action.payload
        }
      }
    },

    /**
     * обновить выбранный фильтр в маркетплейсе
     * @param state
     * @param action
     */
    updateMarketplaceFilter(state, action: PayloadAction<IProjectsSortType | undefined>) {
      state.projectMarketplace = {
        ...state.projectMarketplace,
        filterOptions: {
          ...state.projectMarketplace.filterOptions,
          projectType: action.payload
        }
      }
    },

    /**
     * обновить список карточек проектов в маркетплейсе
     * @param state
     * @param action
     */
    updateMarketplaceProjectsList(state, action: PayloadAction<Array<IProjectDTO>>) {
      state.projectMarketplace = {
        ...state.projectMarketplace,
        projectsList: [...(action.payload)]
      }
    },

    /**
     * обновить информацию о постраничности
     * @param state
     * @param action
     */
    updateMarketplacePagination(state, action: PayloadAction<IProjectMarketplacePagination>) {
      state.projectMarketplace = {
        ...state.projectMarketplace,
        pagination: action.payload
      }
    },

    /**
     * установить флаг последней страницы в выборке
     * @param state
     * @param action
     */
    updateMarketplaceLastPage(state, action: PayloadAction<boolean>) {
      state.projectMarketplace = {
        ...state.projectMarketplace,
        last: action.payload
      }
    },

    /**
     * обновить в редаксе основную информацию о проекте
     * @param state
     * @param action
     */
    updateProjectData(state, action: PayloadAction<IProjectDTO>) {
      state.project = {
        ...state.project,
        projectData: {
          ...state.project.projectData,
          ...action.payload
        }
      }
    },

    /**
     * обновить список идей для отображения в редаксе
     * @param state
     * @param action
     */
    updateAllIdeasByProject(state, action: PayloadAction<Array<IIdeaDTO>>) {
      state.project = {
        ...state.project,
        ideas: {
          ...state.project.ideas,
          ideasList: action.payload
        }
      }
    },

    /**
     * отдельно взятая идея для отображения (экспертиза)
     * @param state
     * @param action
     */
    setCurrentIdeaInProject(state, action: PayloadAction<IIdeaDTO>) {
      state.project = {
        ...state.project,
        ideas: {
          ...state.project.ideas,
          currentIdea: action.payload
        }
      }
    },

    /**
     * обновить список полномочий пользователя по отношению к идеям
     * @param state
     * @param action
     */
    updateIdeasCapabilities(state, action: PayloadAction<Array<IIdeasCapability>>) {
      state.project = {
        ...state.project,
        ideas: {
          ...state.project.ideas,
          capabilities: action.payload
        }
      }
    },

    /**
     * обновить схему идей текущего проекта
     * @param state
     * @param action
     */
    updateIdeaSchemaInProject(state, action: PayloadAction<IDocumentSchemaDTO>) {
      state.project = {
        ...state.project,
        ideaSchema: action.payload
      }
    },

    /**
     * добавить активности проекта
     * @param state
     * @param action
     */
    updateAllActivitiesInProject(state, action: PayloadAction<Array<IActivityInProjectDTO>>) {
      state.project = {
        ...state.project,
        activities: action.payload
      }
    },

    /**
     * добавить активность в список
     * @param state
     * @param action
     */
    addActivityInProject(state, action: PayloadAction<IActivityInProjectDTO>) {
      if (!state.project?.activities?.find( (activity: IActivityInProjectDTO) => activity.id === action.payload.id ))
        state.project = {
          ...state.project,
          activities: [...state.project.activities, action.payload]
        }
    },

    /**
     * обновить одну активность
     * @param state
     * @param action
     */
    updateActivityInProject(state, action: PayloadAction<IActivityInProjectDTO>) {
      state.project = {
        ...state.project,
        activities: state.project?.activities?.map(
          (activity: IActivityInProjectDTO) => activity.id === action.payload.id ? action.payload : activity
        )
      }
    },

    /**
     * удалить активность по её id
     * @param state
     * @param action
     */
    deleteActivity(state, action: PayloadAction<string>) {
      state.project = {
        ...state.project,
        activities: state.project?.activities?.filter(
          (activity: IActivityInProjectDTO) => activity.id !== action.payload
        )
      }
    },

    /**
     * обновить список идей в состоянии
     * @param state
     * @param action
     */
    updateCurrentIdeasList(state, action: PayloadAction<IIdeaDTO[]>) {
      state.project = {
        ...state.project,
        ideas: {
          ...state.project?.ideas,
          ideasList: action.payload
        }
      }
    },

    /**
     * обновить одну идею в состоянии
     * @param state
     * @param action
     */
    updateIdeaInRedux(state, action: PayloadAction<IIdeaDTO>) {
      state.project = {
        ...state.project,
        ideas: {
          ...state.project?.ideas,
          ideasList: state.project?.ideas?.ideasList?.map(
            (idea: IIdeaDTO) => idea.id === action.payload.id ? action.payload : idea
          )
        }
      }
    },

    /**
     * обновить количество комментариев в идее
     * @param state
     * @param action
     */
    updateCommentsCountInIdea(state, action: PayloadAction<{ideaId: string, count: number}>) {
      state.project = {
        ...state.project,
        ideas: {
          ...state.project?.ideas,
          ideasList: state.project?.ideas?.ideasList?.map(
            (idea: IIdeaDTO) => (
              idea.id === action.payload.ideaId
                ? {
                  ...idea,
                  totalComments: action.payload.count
                }
                : idea
            )
          )
        }
      }
    },

    /**
     * установить id блока, на который нужно переместиться
     */
    setInitiativeBlockForView(state, action: PayloadAction<string>) {
      state.initiatives = {
        ...state.initiatives,
        initiativeExpertize: {
          ...state.initiatives.initiativeExpertize,
          blockInView: action.payload
        }
      }
    },

    /**
     * Обновление формы подачи инициативы
     */
    setInitiativeCurrentForm(state, action: PayloadAction<any>) {
      state.initiatives = {
        ...state.initiatives,
        initiativeCurrentForm: action.payload
      }
    },

    /**
     *
     * @param state
     * @param action
     */
    setCurrentIdeaCheckboxStatus(state, action: PayloadAction<{groupId: string, checkboxId: string, checked: boolean}>) {
      const calculator = (state.project?.ideas?.currentIdea?.document?.attributes || []).find(
        (attribute: IDocumentAttribute) => attribute.type?.value === 'calculator'
      )?.value as IAttributeCalculator;

      if (calculator) {
        if (action.payload.groupId === 'clientExp') {
          if (!calculator.clientExp)
            calculator.clientExp = {
              title: '',
              checkboxList: []
            }

          if (!calculator.clientExp.checkboxList)
            calculator.clientExp.checkboxList = [];

          if (!calculator.clientExp.checkboxList.find((cb: IAttributeCalculatorCheckBox) => cb.id === action.payload.checkboxId))
            calculator.clientExp.checkboxList.push({id: action.payload.checkboxId, name: 'name', value: action.payload.checked})

          const newCheckboxList = calculator.clientExp.checkboxList?.map(
            (checkbox: IAttributeCalculatorCheckBox) => {
              if (checkbox.id === action.payload.checkboxId) return {...checkbox, value: action.payload.checked}
              else return checkbox
            }
          )

          calculator.clientExp.checkboxList = newCheckboxList;

          state.project = {
            ...state.project,
            ideas: {
              ...state.project?.ideas,
              currentIdea: {
                ...state.project?.ideas?.currentIdea,
                document: {
                  ...state.project?.ideas?.currentIdea?.document,
                  attributes: (state.project?.ideas?.currentIdea?.document?.attributes || []).map(
                    (attribute: IDocumentAttribute) => {
                      if (attribute.type?.value === 'calculator') return {...attribute, value: calculator as any}
                      else return attribute;
                    }
                  )
                }
              }
            }
          }
        }
      }
    },

    /**
     * Обновление поля по id формы подачи инициативы
     */
    setInitiativeFieldById(state, action: PayloadAction<any>) {
      if (!action.payload.id) return;

      // state.initiatives = {
      //   ...state.initiatives,
      //   initiativeCurrentForm: {
      //     fields: state.initiatives.initiativeCurrentForm.fields.map(
      //       (f: any) => f.id === action.payload.id ? {...f, value: action.payload.value} : f
      //     )
      //   }
      // }

    //   state.project = {
    //     ...state.project,
    //     ideas: {
    //       ...state.project?.ideas,
    //       ideasList: {
    //         ...state.project?.ideas?.ideasList,
    //         currentIdea: {
    //           ...state.project?.ideas?.ideasList?.currentIdea,
    //           document: {
    //             ...state.project?.ideas?.ideasList?.currentIdea?.document,
    //             attributes: (state.project?.ideas?.ideasList?.currentIdea?.document?.attributes || []).map(
    //               (attribute: IDocumentAttribute) => {
    //                 if (attribute.type?.value === 'calculator') {
    //                   return
    //                 } else return attribute;
    //               }
    //             )
    //           }
    //         }
    //       }
    //     }
    //   }
    },

    /**
     * Обновление поле items по id формы подачи инициативы
     */
    setInitiativeFieldItemsById(state, action: PayloadAction<any>) {
      if (!action.payload.id) return;

      state.initiatives = {
        ...state.initiatives,
        initiativeCurrentForm: {
          fields: state.initiatives.initiativeCurrentForm.fields.map(
            (f: any) => f.id === action.payload.id ? {...f, items: action.payload.value} : f
          )
        }
      }
    },

    /**
     * Обновление поле text по id формы подачи инициативы
     */
    setInitiativeFieldTextById(state, action: PayloadAction<any>) {
      if (!action.payload.id) return;

      state.initiatives = {
        ...state.initiatives,
        initiativeCurrentForm: {
          fields: state.initiatives.initiativeCurrentForm.fields.map(
            (f: any) => f.id === action.payload.id ? {...f, text: action.payload.value} : f
          )
        }
      }
    },

    /**
     * Обновление чекбокса в списке чекбоксов поля по id
     */
    setInitiativeCheckBoxById(state, action: PayloadAction<any>) {
      if (!action.payload.id) return;

      state.initiatives = {
        ...state.initiatives,
        initiativeCurrentForm: {
          fields: state.initiatives.initiativeCurrentForm.fields.map(
            (f: any) => f.id === action.payload.id ? {...f,
              checkboxList: f.checkboxList.map( (c: any) => c.id === action.payload.checkboxid? {...c, value: !c.value} : c )
            } : f
          )
        }
      }
    },


  }
})

export const {
  updateMarketplacePermissions,
  updateMarketplaceProjectTypes,
  updateMarketplaceSearchString,
  updateMarketplaceFilter,
  updateMarketplaceProjectsList,
  updateMarketplacePagination,
  updateMarketplaceLastPage,

  updateProjectData,
  updateAllIdeasByProject,
  setCurrentIdeaInProject,
  updateIdeasCapabilities,
  updateIdeaSchemaInProject,
  updateAllActivitiesInProject,
  addActivityInProject,
  updateActivityInProject,
  deleteActivity,
  updateIdeaInRedux,
  updateCommentsCountInIdea,

  setCurrentIdeaCheckboxStatus,

  setInitiativeBlockForView,
  setInitiativeCurrentForm,
  setInitiativeFieldById,
  setInitiativeFieldItemsById,
  setInitiativeFieldTextById,
  setInitiativeCheckBoxById
} = projectsSlice.actions;

export default projectsSlice.reducer;