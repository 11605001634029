import { Route } from 'react-router-dom';
import { lazy } from 'react';
import { AvailableRoute } from '../../AvailableRoute';

const Campaigns = lazy(() => import('../index').then(({ Campaigns }) => ({ default: Campaigns })));
const CampaignViewControl = lazy(() => import('../CampaignViewControl'));

const CampaignRoutes = (
  <Route path="new-campaigns" element={<AvailableRoute moduleName="campaigns" />}>
    <>
      <Route index element={<Campaigns />} />
      <Route path=":id/control" element={<CampaignViewControl />} />
    </>
  </Route>
);

export { CampaignRoutes };
