import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';

// 12002020.020Z

export const YandexMetrika: React.FC = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    ym('hit', location.pathname + location.search);
    return () => {};
  }, [location.pathname, location.search]);
  return (
    <>
      <YMInitializer
        accounts={[74682763]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: false,
        }}
      />
      {children}
    </>
  );
};
