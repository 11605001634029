import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type ILandingConstructorSlice = {
	blocksTypesList: Array<any>,
	editorActualEntryId: string,
	editorActualEntryPageItems: Array<any>,
	focusBlock: any,
	editorModalOpen: boolean,
	editorSettingsModalOpen: boolean
}

const landingConstructor = createSlice({
	name: 'landingConstructor',
	initialState: {
		blocksTypesList: [],
		editorActualEntryId: '',
		editorActualEntryPageItems: [],
		focusBlock: {},
		editorModalOpen: false,
		editorSettingsModalOpen: false
	} as ILandingConstructorSlice,
	reducers: {

		clearState(state) {
			state.editorActualEntryId = '';
			state.editorActualEntryPageItems = [];
		},

		setBlocksTypesList(state, action: PayloadAction<Array<any>>) {
			state.blocksTypesList = action.payload;
		},

		setEditorModalOpen(state, action: PayloadAction<boolean>) {
			state.editorModalOpen = action.payload
		},

		setEditorSettingsModalOpen(state, action: PayloadAction<boolean>) {
			state.editorSettingsModalOpen = action.payload
		},

		setEditorActualEntryId(state, action: PayloadAction<string>) {
			state.editorActualEntryId = action.payload
		},

		setEditorActualEntryPageItems(state, action:PayloadAction<[]>) {
			state.editorActualEntryPageItems = action.payload;
		},

		setFocusBlock(state, action:PayloadAction<any>) {
			state.focusBlock = action.payload;
		}

	},
});

export const {
	clearState,
	setBlocksTypesList,
	setEditorModalOpen,
	setEditorSettingsModalOpen,
	setEditorActualEntryId,
	setEditorActualEntryPageItems,
	setFocusBlock,
} = landingConstructor.actions;

export default landingConstructor.reducer;
