import React from 'react';

const Roles = () => {
	return (
		<div>
			Роли
		</div>
	);
};

export { Roles };