import styles from './style.module.scss';
import { Link } from 'react-router-dom';

const Status404 = () => {
  return (
    <div className={ styles.main }>
      <div className={ styles.container }>
        <div className={ styles.status }>
          <div className={ styles.code }>
            <svg viewBox="0 0 452 191" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M86.1597 140.208H0V124.864L81.1382 2.90997H110.739V118.251H135.054V140.208H110.739V188.09H86.1597V140.208ZM86.1597 118.251V30.1579L28.0151 118.251H86.1597Z"
                fill="#525252"
              />
              <path
                d="M227.982 191C218.115 191 209.305 189.413 201.553 186.238C193.976 183.064 187.545 177.773 182.259 170.366C176.974 162.958 172.921 153.17 170.102 141.001C167.283 128.832 165.873 113.665 165.873 95.5C165.873 77.3347 167.283 62.1676 170.102 49.9986C172.921 37.6533 176.974 27.777 182.259 20.3698C187.545 12.9626 193.976 7.75992 201.553 4.76178C209.305 1.58726 218.115 0 227.982 0C238.202 0 247.188 1.49908 254.94 4.49723C262.869 7.31902 269.388 12.4335 274.498 19.8407C279.608 27.0716 283.484 36.8596 286.127 49.205C288.77 61.374 290.091 76.8056 290.091 95.5C290.091 114.018 288.682 129.45 285.863 141.795C283.22 153.964 279.255 163.752 273.969 171.159C268.86 178.39 262.428 183.505 254.676 186.503C246.923 189.501 238.025 191 227.982 191ZM227.982 168.249C234.149 168.249 239.523 167.279 244.104 165.339C248.685 163.399 252.473 159.784 255.469 154.493C258.64 149.202 260.931 141.883 262.34 132.536C263.926 123.012 264.719 110.667 264.719 95.5C264.719 80.3329 263.926 68.0757 262.34 58.7285C260.931 49.205 258.64 41.7978 255.469 36.5069C252.473 31.2161 248.685 27.6006 244.104 25.6607C239.523 23.7207 234.149 22.7507 227.982 22.7507C221.815 22.7507 216.441 23.7207 211.86 25.6607C207.279 27.6006 203.491 31.3042 200.496 36.7715C197.5 42.0623 195.21 49.4695 193.624 58.9931C192.038 68.3403 191.245 80.5092 191.245 95.5C191.245 110.138 192.038 122.219 193.624 131.742C195.386 141.09 197.853 148.497 201.024 153.964C204.196 159.431 208.072 163.223 212.653 165.339C217.234 167.279 222.344 168.249 227.982 168.249Z"
                fill="#525252"
              />
              <path
                d="M403.106 140.208H316.946V124.864L398.084 2.90997H427.685V118.251H452V140.208H427.685V188.09H403.106V140.208ZM403.106 118.251V30.1579L344.961 118.251H403.106Z"
                fill="#525252"
              />
            </svg>
          </div>
          <div className={ styles.cat }>
            <img src="/img/status404cat.svg" alt="" />
          </div>
        </div>

        <div className={ styles.title }>Страница не найдена</div>
        <div className={ styles.description }>
          Она была удалена, либо вообще не существует на сайте.
          <br />
          Вы можете{' '}
          <Link to="/" className={ styles.link }>
            вернуться на главную
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Status404;
